let sliderShowing = $(window).width() > 600;

const ProductSlider = {
	init: () => {
		$(window).on('resize', () => {
			window.requestAnimationFrame(ProductSlider.test);
		});

		if (sliderShowing) ProductSlider.update();
		else ProductSlider.mobileAnimate();
	},
	test: () => {
		const windowWidth = $(window).width();

		if (sliderShowing && windowWidth <= 600) {
			ProductSlider.delete();
			sliderShowing = false;
		} else if (!sliderShowing && windowWidth > 600) {
			ProductSlider.update();
			sliderShowing = true;
		}
	},
	update: () => {
		$('.js-redils--product .redils').redils({
			multiSlide: true,
			breakPoints: [{ breakAfter: 960, numSlides: 4 }, { breakAfter: 600, numSlides: 3 }, { breakAfter: 0, numSlides: 2 }],
			auto: false,
		});
	},
	delete: () => {
		$('.js-redils--product .redils').redils('destroy');
		ProductSlider.mobileAnimate();
	},
	mobileAnimate: () => {
		$('.js-redils--product .slide-cont').animate({ scrollLeft: (window.innerWidth / 4) }, 500);
	},
};

export default ProductSlider;
