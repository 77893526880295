import { changePaymentMethod } from '../shop/connect';
import { paymentMethodsMap } from '../config';

const Payment = {
	init: ($scope, giftCertificate = false) => {
		$scope = $scope || $(document);

		$scope.on('change', '#js-paymentMethods input', function () {
			const paymentMethod = $(this).val();
			changePaymentMethod(paymentMethod, giftCertificate)
				.then((response) => {
					$('#js-adyenDropInContainer').html('');
					$('#checkout_order_proceed').show();
					$('#giftCert_order_proceed').show();

					const klarnaIsSelected = paymentMethodsMap.klarnaCheckout.indexOf(response.data.selection.paymentMethod) !== -1;
					if (response.data?.selection?.pluginFields?.paymentHTML && $('#js-klarnaCheckout').html() === '') {
						$('#js-klarnaCheckout').html(response.data.selection.pluginFields.paymentHTML);
					}

					if (klarnaIsSelected) {
						$('#js-addressFormContainer,#js-addressFormContainer-finish').hide();
						$('#js-klarnaCheckout').show();
					} else {
						$('#js-klarnaCheckout').hide();
						$('#js-addressFormContainer,#js-addressFormContainer-finish').show();
					}

					return response;
				})
				.catch(() => console.log('ERROR: Failed to update fields'));
		});
	},
};

export default Payment;
