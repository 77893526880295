import Common from '../common';

const RecentlyViewed = {
	add(product) {
		/*
		var product = {
			isFavorite: false,
			id: '',
			link: '',
			image: [],
		}
		*/
		const recents = Common.getCookie('recents') || '[]';
		const index = recentsArray.indexOf(product.id);
		let recentsArray = JSON.parse(decodeURIComponent(recents));

		if (index === -1) {
			recentsArray.unshift(product.id);
		} else {
			recentsArray.splice(index, 1);
			recentsArray.unshift(product.id);
		}

		recentsArray = recentsArray.slice(0, 10);

		Common.setCookie('recents', recentsArray);

		$('#js-recentlyViewed').closest('recentlyViewed').removeClass('recentlyViewed--empty');
		$('#js-recentlyViewed').children(':nth-child(n + 6)').remove();
		$('#js-recentlyViewed').prepend(`<div class="productList productList--recentlyViewed">
			<a class="js-saveToFavorites productImages-main-favorites productList-favorites${product.isFavorite ? ' productList-favorites--selected' : ''}" href="#save-to-favorites" data-id="${product.id}">
				<svg xmlns="http://www.w3.org/2000/svg" width="31px" height="27px" viewBox="0 0 31 27">
					<path d="M21.8,2.55c1.14,0,2.21,0.28,3.2,0.82 c2.12,1.18,3.44,3.41,3.44,5.82c0,0.69-0.11,1.38-0.33,2.04l-0.02,0.07l-0.02,0.07c-0.26,1.19-1.09,2.61-2.45,4.22 c-1.17,1.38-2.7,2.86-4.57,4.42c-2.23,1.86-4.41,3.37-5.55,4.13c-1.14-0.76-3.3-2.26-5.53-4.11c-1.88-1.56-3.42-3.05-4.59-4.42 c-1.37-1.61-2.2-3.04-2.46-4.23L2.9,11.31l-0.02-0.07c-0.22-0.66-0.33-1.35-0.33-2.04c0-2.42,1.32-4.65,3.44-5.82 c1-0.55,2.07-0.83,3.2-0.83c1.98,0,3.85,0.88,5.12,2.41l1.19,1.44l1.19-1.44C17.96,3.42,19.82,2.55,21.8,2.55"/>
				</svg>
			</a>
			<a class="productList-link" href="${product.link}">
				<div class="productList-image">
					<img src="${product.image[0] ? product.image[0] : ''}" alt="">
					${product.image[1] ? `<img src="${product.image[1]}" alt="">` : ''}
				</div>
			</a>
		</div>`);
	},
};

export default RecentlyViewed;
