import { setCookie, getCookie } from '../utils/dom';

const CookieConsent = {
	init: () => {
		const $scope = $(document);
		const consent = {};

		if (getCookie('cookie_consent') && $('#js-cookieConsent')) $('#js-cookieConsent').hide();

		const updateCookieConsent = (cookieSettings) => {
			if (typeof window.gtag !== 'undefined') {
				consent.functionality_storage = 'granted';
				consent.security_storage = 'granted';
				consent.ad_storage = cookieSettings.indexOf('marketing') !== -1 || cookieSettings.indexOf('all') !== -1 ? 'granted' : 'denied';
				consent.ad_user_data = cookieSettings.indexOf('marketing') !== -1 || cookieSettings.indexOf('all') !== -1 ? 'granted' : 'denied';
				consent.ad_personalization = cookieSettings.indexOf('marketing') !== -1 || cookieSettings.indexOf('all') !== -1 ? 'granted' : 'denied';
				consent.analytics_storage = cookieSettings.indexOf('analytics') !== -1 || cookieSettings.indexOf('all') !== -1 ? 'granted' : 'denied';
				consent.personalization_storage = 'granted';

				const hrq = window.hrq || [];
				hrq.push(['setTrackingOptOut', false]);

				window.gtag('consent', 'update', consent);
				if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
				window.dataLayer.push({
					event: 'ConsentUpdated',
					values: {},
				});
			}
		};

		$scope.on('click', '.js-allCookies', (e) => {
			e.preventDefault();
			const $this = $(e.currentTarget);
			const cookieDays = $this.data('duration') / 86400;
			const saveSettings = ['all'];

			updateCookieConsent(saveSettings);
			setCookie('cookie_consent', saveSettings, cookieDays);
			$this.closest('#js-cookieConsent').hide();
		});

		$scope.on('click', '.js-saveCookieOptions', (e) => {
			e.preventDefault();
			const $this = $(e.currentTarget);
			const cookieDays = $this.data('duration') / 86400;
			let saveSettings = [];

			$this.closest('#js-cookieConsent').find('input:checked').each((i, el) => {
				saveSettings.push($(el).val());
			});

			if (saveSettings.length === $this.closest('#js-cookieConsent').find('input[type="checkbox"]').length) {
				saveSettings = ['all'];
			}

			updateCookieConsent(saveSettings);
			setCookie('cookie_consent', saveSettings, cookieDays);
			$this.closest('#js-cookieConsent').hide();
		});

		$scope.on('click', '.js-manageCookies', (e) => {
			e.preventDefault();
			const $this = $(e.currentTarget);

			$this.siblings('.js-allCookies').addClass('u-button--outlineBlack');
			$this.closest('#js-cookieConsent').addClass('modal--showOptions');
		});
	},
};

export default CookieConsent;
