import { addVoucher, removeVoucher } from '../shop/connect';

const Voucher = {
	init: ($scope) => {
		$scope = $scope || $(document);

		$scope.on('submit', '#js-addVoucher', function (e) {
			e.preventDefault();
			const $this = $(this);
			const voucherCode = $this.find('input').val();

			addVoucher(voucherCode)
				.then((response) => {
					if (response.type === 'VOUCHER_ERROR') $('#checkout_voucher_code').addClass('is-error');
					return response;
				})
				.catch(() => {
					$('#checkout_voucher_code').addClass('is-error');
				});
		});

		$scope.on('focus blur change', '#checkout_voucher_code', function () {
			$(this).removeClass('is-error');
		});

		$scope.on('click', '#js-removeVoucher', function (e) {
			e.preventDefault();
			const voucherCode = $(this).val();

			removeVoucher(voucherCode);
		});
	},
};

export default Voucher;
