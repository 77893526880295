import Header from './header';
import Modal from './modal';
import Popup from './popup';
import Search from './search';
import Sticky from './sticky';
import Newsletter from './newsletter';
import SetCookie from './set_cookie';
import CookieConsent from './cookie_consent';
import ReloadCountry from './reload_country';
import SaveFavorite from './save_favorite';
import ListingAddToBag from './listing_add_to_bag';
import ProductPreview from '../product/preview';
import ProductSlider from './product_slider';
import LoadImages from './load_images';
import TreeCounter from '../content/treeCounter';

const Index = {
	init: () => {
		CookieConsent.init();
		LoadImages.init();
		Header.init();
		Modal.init();
		Search.init(); // Get filter object.
		Sticky.init();
		SetCookie.init();
		ProductPreview.init();
		ReloadCountry.init();
		Newsletter.init();
		SaveFavorite.init();
		ListingAddToBag.init();
		ProductSlider.init();
		Popup.init();
		TreeCounter.init();

		$('a[href$="/#newsletter"]').on('click', (e) => {
			e.preventDefault();

			const currentNewsletterPosition = $('#js-newsletter-container').show().offset().top;

			$('html,body').animate({
				scrollTop: currentNewsletterPosition,
			}, 300, () => {
				$('#js-newsletter-container').find('input').focus();
			});
		});

		$('#js-closeNewsletter').on('click', (e) => {
			e.preventDefault();

			$('#js-newsletter-container').hide();
		});

		$('#js-footer').on('click', '.js-footerTitle', function (e) {
			e.preventDefault();

			$(this).parent().toggleClass('footer-main-column--open');
		});
	},
};

export default Index;
