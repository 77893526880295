import Modal from '../general/modal';

const SizeGuide = {
	init($scope) {
		$scope = $scope || $(document);

		$scope.on('click', '.js-productSizeGuideLink', (e) => {
			e.preventDefault();

			Modal.init($scope.find('.js-sizeGuide').html(), $scope.find('.js-sizeGuide').data('width'));
		});
	},
};

export default SizeGuide;
