import { changeQuantity } from '../shop/connect';
import SaveFavorite from '../general/save_favorite';

const EditQuantity = {
	init: ($scope) => {
		$scope = $scope || $(document);

		$scope.on('click', '.js-editQuantity button', function (e) {
			e.preventDefault();

			const line = $(this).attr('name');
			const quantity = $(this).val();

			if ($(this).attr('name').indexOf('delete') !== -1) {
				SaveFavorite.save($(this).data('id'), true);
			}

			changeQuantity(line, quantity);
		});
	},
};

export default EditQuantity;
