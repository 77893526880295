import { getCookie } from '../utils/dom';
import Common from '../common';
import ProductPreview from '../product/preview';

const Filter = {
	init: () => {
		const favorites = Common.getCookie('favorites') || '[]';
		const favoritesArray = JSON.parse(decodeURIComponent(favorites));
		const { market } = getCookie('store', true);

		$('#refilt').refilt({
			appendItems: true,
			classProductNew: 'productList--new',
			sortFiltersAlphabetically: ['categories', 'swatch'],
			historyCategories: true,
			filterOptions: {
				categories_: {
					ssr: true,
				},
			},
			beforeItemsRendered: (type) => {
				if (type === 'replace') {
					$('#js-productPreview').removeClass('is-showingProductPreview');
				}
			},
			onFilterChanged: (filterObject) => {
				const $rekaf = $('.filterControls-group').find('.rekaf');
				let isFiltered = false;

				$rekaf.rekaf('update').removeClass('rekaf--opened').find('ul').hide();

				Object.keys(filterObject).forEach((filter) => {
					if (filter === 'page') return;
					isFiltered = true;
				});

				if (isFiltered) {
					$('#js-removeAll').addClass('is-showing');
				} else {
					$('#js-removeAll').removeClass('is-showing');
				}

				// Remove opened preview container.
				ProductPreview.closeProductPreview();
			},
			eachItemAttrs: ($this, currentItem) => {
				currentItem.favoriteSelected = '';

				if (favoritesArray.indexOf(parseInt(currentItem.id, 10)) !== -1) {
					currentItem.favoriteSelected = ' productList-favorites--selected';
				}

				if (currentItem.price && currentItem.price.soldout) {
					currentItem.productSoldOut = ' productList--soldOut';
				}

				currentItem.sizes = Object.keys(currentItem.items).filter((item) => (
					currentItem.items[item].stockByMarket[market] !== 0
				)).map((item) => `<button class="productList-size" type="button" value="${currentItem.items[item].item}">${currentItem.items[item].name}</button>`).join('');

				return currentItem;
			},
			afterFilterRendered: () => {
				const $rekaf = $('.filterControls-group').find('.rekaf');

				$rekaf.rekaf({
					selectedClass: 'u-selected',
					disabledClass: 'u-disabled',
					useScreen: false,
					zIndex: 999,
				});

				$('.filterControls-group').each(function () {
					if ($(this).find('li').length > 7) {
						$(this).addClass('filterControls-group--scroll');
					}
					$(this).find('.rekaf-title').addClass('filter-title');
				});

				// Remove opened preview container.
				ProductPreview.closeProductPreview();

				if ($('#categories_3').find('a').length === 0) {
					$('#categories_3').hide();
				} else {
					$('#categories_3').show();
				}
			},
		});
	},
};

export default Filter;
