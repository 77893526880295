import { getCookie } from '../utils/dom';
import { generateEventId, urlify, createSha256Hash, facebookCountry } from '../utils/string';

const Analytics = async (selection, { currency }) => {
	if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
	const uniqueEventID = generateEventId();
	const { address = {} } = selection;
	const store = getCookie('store', true);
	const consent = getCookie('cookie_consent', true);

	let discount = 0;
	const tax = selection.items[0] ? selection.items[0].priceEachAsNumber / selection.items[0].priceEachWithoutTaxAsNumber : 1;
	const coupon = selection.discounts.vouchers.reduce((acc, obj) => [...acc, obj.voucher], []).join(', ');

	if (selection.totals.totalDiscountPriceAsNumber) {
		// remove shipping then tax to get approx discount to send to CJ.
		discount = Math.round(((Math.abs(selection.totals.totalDiscountPriceAsNumber) - selection.totals.shippingPriceAsNumber) / tax) * 100) / 100;
		if (discount < 0) discount = 0;
	}

	/* General Conversion Values */
	window.dataLayer.push({
		event: 'conversion',
		order: `${selection.order || ''}`,
		currency,
		total: parseInt(selection.totals.grandTotalPriceAsNumber, 10),
		totalExVatExShipping: parseInt(selection.totals.grandTotalPriceAsNumber, 10) - parseInt(selection.totals.grandTotalPriceTaxAsNumber, 10) - parseInt(selection.totals.shippingPriceAsNumber, 10),
		vouchers: (selection.discounts.vouchers && selection.discounts.vouchers.map((voucher) => voucher.description).join(' ')) || '',
	});

	/* Google Analytics */
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'purchaseEvent',
		ecommerce: {
			currencyCode: `${selection.currency}`,
			purchase: {
				actionField: {
					id: `${selection.order}`,
					affiliation: `${selection.affiliateName || ''}`,
					revenue: `${selection.totals.grandTotalPriceAsNumber}`,
					tax: `${selection.totals.grandTotalPriceTaxAsNumber}`,
					shipping: `${selection.totals.shippingPriceAsNumber}`,
					coupon: (selection.discounts.vouchers && selection.discounts.vouchers.map((voucher) => voucher.description).join(' ')) || '',
				},
				products: selection.items.map((item) => ({
					name: `${item.name}`.trim(),
					sku: `${item.sku}`,
					price: `${item.priceEachAsNumber}`,
					brand: `${item.brandName}`.trim(),
					category: `${(item.foldersName || '')}`.trim(),
					variant: `${item.variantName}`.trim(),
					quantity: `${item.quantity}`.trim(),
				})),
			},
		},
	});

	/* Facebook */
	window.dataLayer.push({ values: null });
	window.dataLayer.push({
		event: 'Purchase',
		event_id: uniqueEventID,
		values: {
			currency,
			content_type: 'product',
			contents: selection.items.map((item) => ({
				id: `${item.id.replace(/6\/\w{2}/, `6/${facebookCountry(address)}`)}`,
				quantity: `${item.quantity}`,
				item_price: `${item.priceEachAsNumber}`,
			})),
			num_items: `${selection.totals.totalQuantity}`,
			value: `${selection.totals.grandTotalPriceAsNumber}`,
			em: address.email,
			fn: address.firstName,
			ln: address.lastName,
			ph: address.phoneNumber,
			ct: address.city, // city
			st: address?.state || '', // state
			zp: address.zipcode, // zip
			country: address.country,
		},
	});

	/* Pinterest */
	window.dataLayer.push({ values: null });
	window.dataLayer.push({
		event: 'Checkout',
		values: {
			value: `${selection.totals.grandTotalPriceAsNumber}`,
			order_quantity: `${selection.totals.totalQuantity}`,
			currency,
			line_items: selection.items.map((item) => ({
				product_name: item.name,
				product_id: item.id,
				product_price: `${item.priceEachAsNumber}`,
				product_quantity: `${item.quantity}`,
			})),
		},
	});

	/* TikTok */
	window.dataLayer.push({ values: null });
	window.dataLayer.push({
		event: 'CompletePaymentTikTok',
		values: {
			currency,
			contents: selection.items.map((item) => ({
				content_id: item.id,
				content_type: 'product',
				content_name: item.name,
				quantity: `${item.quantity}`,
				price: `${item.priceEachAsNumber}`,
			})),
			quantity: `${selection.totals.totalQuantity}`,
			value: `${selection.totals.grandTotalPriceAsNumber}`,
		},
	});

	if (!window.cj) window.cj = {};

	// Marketing only
	const emailHash = await createSha256Hash(address.email);

	/* CJ */
	window.dataLayer.push({ cjData: null });
	window.dataLayer.push({
		event: 'CJConversionConfirmation',
		cjData: {
			emailHash,
			orderId: selection.order,
			actionTrackerId: '435475',
			currency: selection.currency,
			cartSubtotal: `${(selection.items.reduce((acc, item) => (acc + (item.priceEachWithoutTaxAsNumber * item.quantity)), 0) - discount).toFixed(2)}`,
			discount,
			coupon: selection.discounts.vouchers.length > 0 ? urlify(coupon) : 0,
			customerCountry: address.country,
			items: selection.items.map((item) => ({ unitPrice: item.priceEachWithoutTaxAsNumber, itemId: item.sku, quantity: item.quantity })),
		},
	});

	const eventTime = encodeURIComponent(new Date().toISOString()).replace(/[.]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
	const cjImage = document.createElement('img');
	cjImage.src = `https://www.emjcd.com/u?CID=1564969&TYPE=435475&METHOD=S2S&SIGNATURE=leexKGBU081FFFK947F9Nq57&CJEVENT=${getCookie('cje') || ''}&eventTime=${eventTime}&OID=${selection.order}&currency=${selection.currency}&coupon=${selection.discounts.vouchers.length > 0 ? urlify(coupon) : 0}&${selection.items.reduce((acc, item, i) => [...acc, `ITEM${i + 1}=${encodeURIComponent(item.sku)}&AMT${i + 1}=${item.priceEachWithoutTaxAsNumber}&QTY${i + 1}=${item.quantity}`], []).join('&')}&discount=${discount}&customerCountry=${address.country}`;
	cjImage.id = 'cjPixel';
	cjImage.style.width = '1px';
	cjImage.style.height = '1px';
	document.body.appendChild(cjImage);

	/* Good on You */
	if (typeof window.EF !== 'undefined') {
		window.EF.conversion({
			aid: 13,
			amount: `${selection.totals.grandTotalPriceAsNumber}`,
			order_id: `${selection.order || ''}`,
			adv1: currency,
			adv2: `${selection.order || ''}`,
			parameters: {
				currency,
			},
		});
	}

	/* Hello Retail */
	const _awev = window._awev || []; // eslint-disable-line no-underscore-dangle
	_awev.push(['bind_once', 'context_ready', () => {
		window.ADDWISH_PARTNER_NS.api.conversion.track_sale({
			total: `${selection.totals.grandTotalPriceAsNumber}`,
			orderNumber: `${selection.order || ''}`,
			email: `${address.email || ''}`,
			productNumbers: selection.items.map((item) => item.sku),
		});
	}]);
};

export default Analytics;
