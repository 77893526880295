import store from '../shop/store';
import { renderStates } from '../shop/render';
import { changeAddressInput, booleanChange, updateKlarna } from '../shop/connect';
import { fieldValidation } from '../utils/string';

const Validate = {
	updatePaymentMethods: false,
	init: ($parent, giftCertificate = false) => {
		const $scope = $parent || $(document);

		if ($('#checkout_order_newsletter,#giftCert_order_newsletter').is(':checked')) {
			$('#checkout_order_newsletter').closest('.addressForm-newsletter').addClass('is-selected');
			$('#giftCert_order_newsletter').closest('.addressForm-newsletter').addClass('is-selected');
		}

		$scope.on('change', '#checkout_order_newsletter,#giftCert_order_newsletter', function () {
			$(this).closest('.addressForm-newsletter').toggleClass('is-selected', $(this).is(':checked'));
		});

		// Dynamic error checking
		$scope.on('blur keydown change', 'input.is-required:visible,#checkout_order_termsAndConditions,#giftCert_order_termsAndConditions', function (e) {
			const $input = $(this);
			const $formField = $input.closest('.addressForm-field');

			if (e.type === 'focusout') {
				if ($formField.closest('#js-shippingAddress').length > 0 && !$('#checkout_shippingAddress_useShipping').is(':checked')) {
					$formField.removeClass('is-error');
				} else if ($input.attr('type') === 'checkbox' && !$input.is(':checked')) {
					$formField.addClass('is-error');
				} else if (fieldValidation($input.val(), $input.attr('id'))) {
					$formField.addClass('is-error');
				} else {
					$formField.removeClass('is-error');
				}
			} else {
				$formField.removeClass('is-error');
				$('#checkout_order_proceed').removeClass('is-error');
				$('#giftCert_order_proceed').removeClass('is-error');
			}
		});

		$scope.on('change', '#checkout_address_state', function () {
			const $input = $(this);
			const $formField = $input.closest('.addressForm-field');

			$formField.removeClass('is-error');
			$('#checkout_order_proceed').removeClass('is-error');
			$('#giftCert_order_proceed').removeClass('is-error');
		});

		// Save information to store.
		$scope.on('keydown change', 'input,select', function () {
			Validate.saveField($(this));
		});

		$scope.on('blur', 'input,select', function () {
			const $this = $(this);
			setTimeout(() => {
				Validate.saveField($this);

				updateKlarna(giftCertificate);
				Validate.updatePaymentMethods = false;
			}, 50);
		});
	},
	checkFields: () => {
		let hasErrors = false;

		$('#js-selectionForm').find('input.is-required:visible,#checkout_order_termsAndConditions,#giftCert_order_termsAndConditions').each(function () {
			const $input = $(this);

			if ($input.attr('type') === 'checkbox' && !$input.is(':checked')) {
				hasErrors = true;
			} else if (fieldValidation($input.val(), $input.attr('id'))) {
				hasErrors = true;
			}
		});

		return hasErrors;
	},
	saveField: ($input) => {
		const { payment = {} } = store.getState();
		const ignoreInputs = ['giftCert_items_item', 'checkout_voucher_code'];
		if ($input[0].className.indexOf('adyen') !== -1 || $input.attr('type') === 'hidden' || ignoreInputs.indexOf($input.attr('id')) !== -1) return;
		if (!$input.attr('id')) return;
		const [, group, field] = $input.attr('id').split('_');

		if ($input.attr('type') === 'checkbox') {
			if (field === 'newsletter') booleanChange('subscribeToNewsletter', $input.prop('checked'));
			else booleanChange(field, $input.prop('checked'));
			Validate.updatePaymentMethods = true;
			return;
		}

		let value = $input.val();
		if (field === 'phoneNumber') {
			if (/^0+/.test(value)) value = value.replace(/^0+/, '');
		}
		const nextGroup = (group !== 'address' && group !== 'shippingAddress' ? '' : group);
		const prevState = payment?.[nextGroup]?.[field] || payment?.[field] || '';
		changeAddressInput(nextGroup, field, value);

		if (prevState !== value && !Validate.updatePaymentMethods) Validate.updatePaymentMethods = true;
	},
	updateFields: () => {
		const { payment = {}, selection = {}, countries = {}, useShippingAddress } = store.getState();

		// Initate data
		$('#js-selectionForm').find('input,select').each(function () {
			if ($(this)[0].className.indexOf('adyen') !== -1 || $(this).attr('type') === 'hidden' || $(this).attr('type') === 'radio') return;
			const [selectionType, group, field] = ($(this).attr('id') || '').split('_');

			const fieldSettings = selection.paymentFields?.[group]?.[field] || selection.paymentFields?.[field];

			if (selectionType === 'giftCert') {
				$(this).addClass('is-required').closest('.addressForm-field').show();
			} else if (fieldSettings) {
				if (fieldSettings.required) $(this).addClass('is-required');
				if (!fieldSettings.required) $(this).removeClass('is-required');
				if (fieldSettings.visible) $(this).closest('.addressForm-field').show();
				if (!fieldSettings.visible) $(this).closest('.addressForm-field').hide();
			}

			if (field === 'state') {
				if (countries?.[payment[group].country]?.states) {
					$(this).html(renderStates(countries, { country: payment[group].country })).val('');
					$(this).addClass('is-required').closest('.addressForm-field').show();
				} else {
					$(this).html('');
					$(this).removeClass('is-required').closest('.addressForm-field').hide();
				}
			}

			const value = payment?.[group]?.[field] || payment?.[field];

			// Set values
			if ($(this).attr('type') === 'checkbox') {
				if (group === 'shippingAddress' && field === 'useShipping') {
					$(this).prop('checked', useShippingAddress);
					$(this).closest('.addressForm-part').toggleClass('addressForm-part--selected', useShippingAddress);
				} else if (group === 'order' && field === 'newsletter') {
					$(this).prop('checked', !!payment?.address?.newsletter);
					$(this).closest('.addressForm-newsletter').toggleClass('is-selected', !!payment?.address?.newsletter);
				} else $(this).prop('checked', !!value);
			}
			if (value) $(this).val(value);

			if (field === 'country') {
				$(this).next().next().css({ backgroundImage: `url("/assets/flags/${$(this).val().toLowerCase()}.svg")` });
			}
		});
	},
};

export default Validate;
