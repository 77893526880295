const dayMilliseconds = 864e5;

const setDate = ({ specific, days, weeks, months, years }) => {
	let additionalTime = specific || 0;
	if (days) additionalTime = dayMilliseconds * days;
	if (weeks) additionalTime = (dayMilliseconds * 7) * weeks;
	if (months) additionalTime = (dayMilliseconds * (365 / 12)) * months;
	if (years) additionalTime = (dayMilliseconds * 365) * years;
	return new Date(Date.now() + additionalTime);
};

const getDate = (date) => {
	date = date || new Date(Date.now());
	return date.getDate();
};

const getMonth = (date, monthArray = []) => {
	date = date || new Date(Date.now());
	return monthArray[date.getMonth()] || date.getMonth();
};

const getYear = (date) => {
	date = date || new Date(Date.now());
	return date.getFullYear();
};

const getFullDate = (date, seperator = '-') => `${getYear(date)}${seperator}${getMonth(date)}${seperator}${getDate(date)}`;

const getWeek = (date, dowOffset = 1) => {
	dowOffset = parseInt(dowOffset, 10);
	let weeknum;

	const newYear = new Date(date.getFullYear(), 0, 1);
	let day = newYear.getDay() - dowOffset; // the day of week the year begins on
	day = (day >= 0 ? day : day + 7);
	const daynum = Math.floor((date.getTime() - newYear.getTime() - (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
	// if the year starts before the middle of a week
	if (day < 4) {
		weeknum = Math.floor((daynum + day - 1) / 7) + 1;
		if (weeknum > 52) {
			const nYear = new Date(date.getFullYear() + 1, 0, 1);
			let nday = nYear.getDay() - dowOffset;
			nday = nday >= 0 ? nday : nday + 7;
			/*
				if the next year starts before the middle of
			  the week, it is week #1 of that year
			*/
			weeknum = nday < 4 ? 1 : 53;
		}
	} else {
		weeknum = Math.floor((daynum + day - 1) / 7);
	}
	return weeknum;
};

const getWeekStartTime = (date, dowOffset = 1) => {
	let day = date.getDay() - dowOffset; // the day of week the year begins on
	day = (day >= 0 ? day : day + 7);
	return Date.parse(date) - (dayMilliseconds * day);
};

const getCurrentUTCOffset = (unit = 'm') => {
	const d = new Date();
	if (unit === 's') return d.getTimezoneOffset() * 60;
	if (unit === 'ms') return d.getTimezoneOffset() * 60 * 1000;
	return d.getTimezoneOffset();
};

export { dayMilliseconds, setDate, getDate, getMonth, getYear, getFullDate, getWeek, getWeekStartTime, getCurrentUTCOffset };
