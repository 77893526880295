import Sticky from './sticky';

const SetCookie = {
	init() {
		$('.js-setCookie').on('click', function (e) {
			e.preventDefault();

			const name = $(this).closest('.js-cookieContainer').data('name');
			let expires = '';
			if ($(this).closest('.js-cookieContainer').data('duration') !== '') {
				let date = new Date();
				date = date.setTime(date.getTime() + $(this).closest('.js-cookieContainer').data('duration') * 1000);
				expires = 'expires=' + new Date(date).toGMTString() + '; ';
			}

			document.cookie = name + '=shown; ' + expires + 'path=/';

			$(this).closest('.js-cookieContainer').fadeOut(200, function () {
				$(this).remove();
				Sticky.measurements();
				Sticky.test();
			});
		});
	},
};

export default SetCookie;
