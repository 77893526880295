import { getCookie } from '../utils/dom';
import { voyadoLocale } from '../utils/locale';
import ProductImages from './images';
import ProductPurchase from './purchase';
import ProductSizeGuide from './size_guide';
import '../category/filter';

const Product = {
	init: () => {
		ProductImages.init();
		ProductPurchase.init();
		ProductSizeGuide.init();

		$('#js-productDetails').on('click', '.js-productDetails-toggle', function (e) {
			e.preventDefault();
			$(this).parent().toggleClass('productDetails-item--open');
		});

		$('#js-productShare').on('click', function (e) {
			e.preventDefault();

			$(this).toggleClass('productDetails-item-links--sharing');
		});

		$('.rekaf').rekaf({
			useScreen: false,
			zIndex: 50,
			useHTML: true,
		});

		const updateVoyadoVariables = () => {
			if (typeof window.va === 'undefined' || typeof window.productDetails === 'undefined' || typeof window.filter.filterDescriptions === 'undefined') {
				setTimeout(() => {
					updateVoyadoVariables();
				}, 200);
				return;
			}

			const store = getCookie('store', true);
			const { items, currentCategory } = window.productDetails;
			const productItems = JSON.parse(items);
			const availableItems = Object.keys(productItems).filter((pI) => productItems[pI].stockByMarket[store.market] > 0).map((pI) => productItems[pI].sku);

			const voyado = {
				categoryName: window.filter.filterDescriptions.categories[currentCategory] ? window.filter.filterDescriptions.categories[currentCategory].replace(/::/g, ' > ') : '',
				itemId: availableItems[Math.floor(availableItems.length / 2)],
				locale: voyadoLocale(store),
			};

			window.va('productview', voyado);
		};

		updateVoyadoVariables();
	},
};

export default Product;
