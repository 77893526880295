import Common from '../common';

const ProductImages = {
	init(isPreview) {
		const grid = {
			colWidth: 110,
			gutWidth: 25,
			columns: 12,
		};
		const offset = 0;
		let thumbsColumns;
		let isScrollable = false;

		if (isPreview) {
			thumbsColumns = ($('#js-productImages').width() === $('#js-productImages').parent().width()) ? 10 : 5;
			$('.productImages-thumbs').height(Common.getGrid(grid, thumbsColumns, offset));

			$('.redils--product').on('redils.updated', function () {
				const $mainImageContainer = $(this);
				const $thumbs = $mainImageContainer.next();

				thumbsColumns = ($('#js-productImages').width() === $('#js-productImages').parent().width()) ? 10 : 5;
				const contHeight = Common.getGrid(grid, thumbsColumns, offset);

				$('.productImages-thumbs').height(contHeight);

				if ($thumbs.find('.productImages-thumbs-item').length > 5) {
					// Add scroll posibilities.
					$thumbs.addClass('is-scrollable');
					isScrollable = true;
					$thumbs.scrollTop(0);
					$thumbs.find('.productImages-thumbs-innerList').css({
						'padding-bottom': (contHeight / 2),
					});
				} else {
					$thumbs.removeClass('is-scrollable');
					$thumbs.removeAttr('style');
				}
			});
		}

		$('.productImages-thumbs').on('click', '.productImages-thumbs-item', function (e) {
			e.preventDefault();

			const thumb = $(this).index();
			const thumbHeight = $(this).height();
			const $thumbs = $(this).closest('.productImages-thumbs-inner');
			const $slider = $(this).closest('.productImages').find('.redils-responsive-height');

			if ($thumbs.scrollTop() + (thumbHeight + 5) * (thumb - 1) > 0 && isScrollable) {
				$thumbs.parent().addClass('is-scrolled');
			} else {
				$thumbs.parent().removeClass('is-scrolled');
			}

			$slider.redils('skipTo', { skipToSlide: thumb });
			$thumbs.animate({
				scrollTop: (thumbHeight + 5) * (thumb - 1) - 23,
			}, 200, 'swing');
		});

		$('.productImages-thumbs').on('click', '.productImages-thumbs-up, .productImages-thumbs-down', function (e) {
			e.preventDefault();

			const $thumbCont = $(this).closest('.productImages-thumbs');
			const $thumbs = $thumbCont.find('.productImages-thumbs-inner');
			const thumbHeight = $thumbs.find('.productImages-thumbs-item').height();
			const currentScroll = $thumbs.scrollTop();

			if ($(this).hasClass('productImages-thumbs-up')) {
				if ((currentScroll - (thumbHeight + 5) * 5) <= 0) {
					$thumbs.parent().removeClass('is-scrolled');
				}
				// Scroll up
				$thumbs.animate({
					scrollTop: (currentScroll - (thumbHeight + 5) * 5),
				}, 200, 'swing');
			} else {
				$thumbs.parent().addClass('is-scrolled');
				// Scroll down
				$thumbs.animate({
					scrollTop: (currentScroll + (thumbHeight + 5) * 5),
				}, 200, 'swing');
			}
		});

		const pswpElement = $('.pswp')[0];
		const items = $('#js-remooz-imagePool').children().map(function () {
			return {
				msrc: $(this).find('img').attr('src'),
				w: 2000,
				h: 2000,
				src: $(this).find('img').attr('src').replace(/(large|standard)/, 'zoom'),
			};
		});

		$('.redils--product').find('.redils-responsive-height').redils({
			autoResize: true,
			setHeight: false,
			auto: false,
		});

		$('#js-productImages').on('click', '.js-zoomImages', (e) => {
			e.preventDefault();

			const options = {
				index: $('#js-remooz-imagePool').find('.current').index() - 1,
				getThumbBoundsFn() {
					const rect = $('#js-productImages')[0].getBoundingClientRect();
					const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
					return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
				},
				history: false,
				shareEl: false,
				fullscreenEl: false,
				barsSize: { top: 20, bottom: 55, left: 0, right: 0 },
			};

			const gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, items, options);
			gallery.init();
		});
	},
};

export default ProductImages;
