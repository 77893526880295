import { changeCountry } from '../shop/connect';

const ReloadCountry = {
	init: () => {
		$('#reload_country').on('change', function () {
			const $this = $(this);
			const newCountry = $this.val();

			changeCountry(newCountry).then(() => (
				$this.closest('form')[0].submit()
			)).catch((err) => {
				console.log('DEBUG: err', err);
			});
		});
	},
};

export default ReloadCountry;
