import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import store from './shop/store';
import { getSetup, getTexts, updateSelection, paymentResult, destroySelection } from './shop/connect';
import render from './shop/render';
import { fetchCheckout } from './utils/async';
import { getCookie, setCookie, deleteCookie, deleteAllCookies } from './utils/dom';
import { baseName } from './utils/string';
import Common from './common';
import GeneralIndex from './general/general';
import ContentIndex from './content/content';
import Product from './product/product';
import Category from './category/category';
import Favorites from './category/favorites';
import Checkout from './checkout/checkout';
import ChangeCountry from './checkout/change_country';
import GiftCert from './giftcert/giftcert';
import Account from './account/account';
import Stores from './stores/stores';

const Site = {};

// eslint-disable-next-line no-underscore-dangle
window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};

Sentry.init({
	dsn: 'https://c986992f6d3648f4a5dfde71321ac6bc@o173594.ingest.sentry.io/4504286464180224',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.01,
	allowUrls: [/https?:\/\/((www)\.)?dedicatedbrand\.com/],
});

let pageType = '';

// Add various Site files here
//------------------------------
Site.receipt = () => {
	if (getCookie('receiptRendered')) destroySelection();
	if (window.tokens) window.tokens = JSON.parse(window.tokens);

	const isGiftCertificate = window.location.pathname.indexOf('gift') !== -1;
	const token = getCookie(isGiftCertificate ? 'tokenGift' : 'token');
	if (token) {
		if (window.location.pathname.indexOf('debug') === -1) {
			paymentResult(token, isGiftCertificate)
				.then((response) => {
					$('#js-receipt-mainContainer').fadeIn(200);
					setCookie('receiptRendered', 1, 14);
					return response;
				})
				.catch((err) => {
					$('#js-receipt-mainContainer').fadeIn(200);
					console.log('DEBUG: receipt failed to load', err);
				});
		} else {
			const storeCookie = getCookie('store', true);
			updateSelection(token, storeCookie.country, storeCookie.language, false)
				.then((response) => {
					$('#js-receipt-mainContainer').fadeIn(200);
					return response;
				}).catch((err) => {
					$('#js-receipt-mainContainer').fadeIn(200);
					console.log('DEBUG: receipt debug failed to load', err);
				});
		}
	}
};

Site.init = () => {
	getTexts();
	getSetup();
	Common.grid();
	Common.animFrame();
	Common.iframeProportions();

	GeneralIndex.init();
	ContentIndex.init();

	if (window.location.pathname.indexOf('clear-data') !== -1) {
		deleteAllCookies(true);
		window.location = window.location.origin;
	}

	if (window.location.pathname.indexOf('receipt') === -1) {
		if (getCookie('receiptRendered')) destroySelection();

		const storeCookie = getCookie('store', true);
		const token = getCookie('token');
		let tokenGift = getCookie('tokenGift');
		if (token === tokenGift) {
			tokenGift = '';
			deleteCookie('tokenGift');
		}

		if (window.location.pathname.indexOf('selection/recover') !== -1) {
			const selectionId = baseName(window.location.pathname);

			fetchCheckout(`/selection/${selectionId}`, false, {}, 'PUT')
				.then((selection) => {
					if (selection.token) {
						updateSelection(selection.token, storeCookie.country, storeCookie.language, false);
						setCookie('token', selection.token, 14);
						window.location = `${window.location.origin}${window.location.pathname.slice(0, window.location.pathname.indexOf('/recover'))}${window.location.search}`;
					}
					return selection;
				}).catch((e) => console.log('DEBUG: e', e));
		} else {
			if (token && window.location.pathname.indexOf('gift') === -1) updateSelection(token, storeCookie.country, storeCookie.language, false);
			if (tokenGift && window.location.pathname.indexOf('gift') !== -1) updateSelection(tokenGift, storeCookie.country, storeCookie.language, true);
		}
	}

	render();
};

Site.start = () => {
	pageType = 'homepage';
};

Site.product = () => {
	pageType = 'productDetail';
	Product.init();
};

Site.category = () => {
	pageType = window.location.pathname.split('/') > 3 ? 'subCategory' : 'category';
	Category.init();
};

Site.favorites = () => {
	Favorites.init();
};

Site.search = () => {
	pageType = 'searchResults';
};

Site.checkout = () => {
	pageType = 'cart';
	Checkout.init();
};

Site.giftCert = () => {
	GiftCert.init();
	ChangeCountry.init();
};

Site.account = () => {
	Account.init();
};

Site.stores = () => {
	pageType = 'storeLocator';
	Stores.init();
};

Site.dev = () => {
	$('.rekaf').rekaf({
		useScreen: false,
		zIndex: 50,
	});

	$('.stylesheetColor').on('click', function () {
		const color = $(this).css('background-color');
		$('.stylesheet').css('background-color', color);
	});
};

Site.finish = () => {
	if (window.location.pathname.indexOf('receipt') === -1) {
		const storeCookie = getCookie('store', true);

		if ($('#js-map').length !== 0) pageType = 'storeLocator';

		const cjData = {
			pageType,
			emailHash: window.localStorage.getItem('hash'),
			customerCountry: storeCookie.country,
		};

		if (pageType === 'cart') {
			const { selection } = store.getState();

			if (selection.totals) {
				cjData.cartSubtotal = `${selection.items.reduce((acc, item) => (acc + (item.priceEachWithoutTaxAsNumber * item.quantity)), 0).toFixed(2)}`;
				cjData.items = selection.items.map((item) => ({ unitPrice: item.priceEachWithoutTaxAsNumber, itemId: item.sku, quantity: item.quantity }));
			}
		}

		if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
		window.dataLayer.push({ cjData: null });
		window.dataLayer.push({
			event: 'CJPageView',
			cjData,
		});
	}
};

// Make Site global
//------------------------------
window.Site = Site;
