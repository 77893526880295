const TreeCounter = {
	init($scope) {
		if ($('.js-treeCounter').length < 1) return;
		$scope = $scope || $(document);

		const animationDuration = 4000;
		const frameDuration = 1000 / 60;
		const totalFrames = Math.round(animationDuration / frameDuration);
		const $spinner = $scope.find('.js-treeCounterSpinner');

		const easeOutQuad = (t) => t * (2 - t);

		const animateCountUp = function ($el) {
			const countTo = $el.data('end');
			let frame = 0;

			const counter = setInterval(() => {
				frame += 1;
				const progress = easeOutQuad(frame / totalFrames);
				const currentCount = Math.round(countTo * progress);

				if (parseInt($el.text(), 10) !== currentCount) {
					$el.text(currentCount >= 1000 ? String(currentCount).replace(/(\d)(?=(\d{3})+$)/g, '$1 ') : currentCount);
				}

				if (frame === totalFrames) {
					clearInterval(counter);
				}
			}, frameDuration);
		};

		const observer = new window.IntersectionObserver((entries) => {
			if (entries[0].intersectionRatio > 0) {
				$spinner.each(function () {
					const $this = $(this);
					animateCountUp($this);
				});

				observer.unobserve($('.js-treeCounter')[0]);
			}
		}, { root: null, threshold: 1 });

		// element to observe
		observer.observe($('.js-treeCounter')[0]);
	},
};

export default TreeCounter;
