const isObject = (obj) => obj && typeof obj === 'object';

const deepClone = (o, skipKey = false) => {
	const out = Array.isArray(o) ? [] : {};
	if (!isObject(o)) return out;
	Object.keys(o).forEach((key) => {
		if (skipKey && key === skipKey) return;
		const v = o[key];
		out[key] = isObject(v) ? deepClone(v) : v;
	});
	return out;
};

const deepMerge = (...objects) => (
	objects.reduce((prev, obj) => {
		Object.keys(obj).forEach((key) => {
			const pVal = prev[key];
			const oVal = obj[key];

			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = [...pVal, ...oVal].filter((element, index, array) => array.indexOf(element) === index);
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = deepMerge(pVal, oVal);
			} else {
				prev[key] = oVal;
			}
		});

		return prev;
	}, {})
);

const deepCompare = (obj1, obj2) => {
	let isEqual = true;

	if (obj2 === undefined) return false;

	Object.keys(obj1).forEach((p) => {
		switch (typeof (obj1[p])) {
			case 'object':
				if (!deepCompare(obj1[p], obj2[p])) isEqual = false;
				break;
			default:
				if (obj1[p] !== obj2[p]) isEqual = false;
		}
	});

	Object.keys(obj2).forEach((p) => {
		if (typeof (obj1[p]) === 'undefined') isEqual = false;
	});

	return isEqual;
};

const deepDiff = (obj1, obj2, exclude = []) => {
	const r = {};

	for (var prop in obj1) {
		if (obj1.hasOwnProperty(prop) && prop != '__proto__') {
			if (exclude.indexOf(obj1[prop]) == -1) {

				// check if obj2 has prop
				if (!obj2.hasOwnProperty(prop)) r[prop] = obj1[prop];

				// check if prop is object and
				// NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
				else if (obj1[prop] === Object(obj1[prop])) {
					const difference = deepDiff(obj1[prop], obj2[prop]);
					if (Object.keys(difference).length > 0) r[prop] = difference;
				}

				// check if obj1 and obj2 are equal
				else if (obj1[prop] !== obj2[prop]) {
					if (obj1[prop] === undefined) r[prop] = 'undefined';
					if (obj1[prop] === null) r[prop] = null;
					else if (typeof obj1[prop] === 'function') r[prop] = 'function';
					else if (typeof obj1[prop] === 'object') r[prop] = 'object';
					else r[prop] = obj1[prop];
				}
			}
		}
	}

	return r;
};

export { deepClone, deepMerge, deepCompare, deepDiff };
