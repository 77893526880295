import ProductImages from './images';
import ProductPurchase from './purchase';
import ProductSizeGuide from './size_guide';
import RecentlyViewed from './recently_viewed';

// Local variables
let currentPosition = 0;
let itemsPerRow;
let currentContWidth;
let containerOffset;
let previousRow;
let estimatedHeight;
let $productList;
let $productPreview;
let $productListContainer;
let $slideCont;
let lowestPosition = 0;
let resized = true;
let cmdHolding = false;
let previewOpened = false;
let timeoutId;

const productPreview = {
	init() {
		$('.js-productPreviewContainer').on('click', '.productList-link', function (e) {
			const $this = $(this);
			if ($(e.target).closest('.js-listingAddToBag').length > 0) return true;
			if (cmdHolding) return true;
			if ($this.parent().hasClass('productList-previewing')) return true;
			e.preventDefault();

			if ($productListContainer !== undefined && !$this.closest('.js-productPreviewContainer').is($productListContainer)) {
				// Preview opened in another section
				productPreview.closeProductPreview();
				setTimeout(() => {
					// Must wait for other section to close.
					productPreview.openPreview($this);
				}, 250);
			} else {
				productPreview.openPreview($this);
			}
		});

		ProductPurchase.init($slideCont);
		ProductSizeGuide.init($slideCont);

		$('.js-productPreviewContainer').on('click', '.js-closePreview', (e) => {
			e.preventDefault();
			productPreview.closeProductPreview();
		});

		$('.js-productPreviewContainer').on('click', '.js-nextProduct', (e) => {
			e.preventDefault();
			$productListContainer.find(`.productList:eq(${currentPosition})`).removeClass('productList-previewing');
			$productListContainer.find(`.productList:eq(${(currentPosition + 1)})`).trigger('click');
		});

		$('.js-productPreviewContainer').on('click', '.js-prevProduct', (e) => {
			e.preventDefault();
			$productListContainer.find(`.productList:eq(${currentPosition})`).removeClass('productList-previewing');
			$productListContainer.find(`.productList:eq(${(currentPosition - 1)})`).trigger('click');
		});

		$(window).on({
			resize() {
				if (!resized && currentContWidth !== $productListContainer.width()) {
					productPreview.closeProductPreview();
					itemsPerRow = undefined;
					currentContWidth = $productListContainer.width();
					estimatedHeight = undefined;
					resized = true;
				}
			},
			keydown(e) {
				if (e.metaKey) cmdHolding = true;
				// Make sure we're not in a textfield
				if ($('input:focus').length === 0 && previewOpened) {
					switch (e.keyCode) {
						case 39:
							// right arrow
							$productListContainer.find(`.productList:eq(${currentPosition})`).removeClass('productList-previewing');
							$productListContainer.find(`.productList:eq(${(currentPosition + 1)})`).trigger('click');
							break;
						case 37:
							// left arrrow
							$productListContainer.find(`.productList:eq(${currentPosition})`).removeClass('productList-previewing');
							$productListContainer.find(`.productList:eq(${(currentPosition - 1)})`).trigger('click');
							break;
						case 27:
							// Esc
							productPreview.closeProductPreview();
							break;
						default:
							break;
					}
				}
			},
			keyup() {
				cmdHolding = false;
			},
		});
	},
	getItemsPerRow() {
		const elementWidth = $productList.eq(0).width();
		currentContWidth = $productListContainer.width();
		const elementWidthPercentage = Math.ceil((elementWidth / currentContWidth) * 100);

		if (elementWidthPercentage < 29) {
			itemsPerRow = 4;
		} else if (elementWidthPercentage >= 29 && elementWidthPercentage < 40) {
			itemsPerRow = 3;
		} else {
			itemsPerRow = 2;
		}
	},
	getLowestPosition(from) {
		let height;
		let offsetTop;
		let $item;

		for (let i = 0; i < itemsPerRow; i += 1) {
			$item = $productList.eq(from + i);
			if ($item.length > 0) {
				offsetTop = $item.offset().top;
				height = $item.height();
				if (offsetTop + height >= lowestPosition) {
					// Lowest item on row
					lowestPosition = Math.ceil(offsetTop + height);
				}
			} else {
				break;
			}
		}
	},
	openPreview($this) {
		previewOpened = true;
		// Get position
		let openDialogOffset = 0;
		const link = $this.attr('href');
		const $productListSpacer = $('.js-productListSpacer').first().clone();
		currentPosition = 0;

		$this.closest('.productList-container').find('.productList').not('.productList--soldOut').each(function(i) {
			if ($(this)[0] === $this.parent()[0]) currentPosition = i;
		});
		resized = false;

		$productListContainer = $this.closest('.js-productPreviewContainer');
		$productPreview = $productListContainer.find('.js-productPreview');
		$slideCont = $productPreview.find('.js-insertSlides');

		$slideCont.removeClass('productPreview-content--loaded');
		$productList = $productListContainer.find('.productList');
		$productListContainer.find('.productList-previewing').removeClass('productList-previewing');
		$this.parent().addClass('productList-previewing');
		if (!$productPreview.hasClass('is-showingProductPreview')) previousRow = undefined;
		containerOffset = $productListContainer.offset().top;

		// Determine how many items in row.
		if (itemsPerRow === undefined || itemsPerRow === 0) {
			productPreview.getItemsPerRow();
		}

		// Change Rows
		const thisRow = Math.floor(currentPosition / itemsPerRow);
		if (thisRow !== previousRow) {
			if ($('.js-productListSpacer').slice(1).length > 0) $('.js-productListSpacer').slice(1).remove();
			$productPreview.removeClass('is-showingProductPreview');
		}

		// Open/move product previewer to correct spot
		const positionOffset = currentPosition % itemsPerRow;
		openDialogOffset = (thisRow > previousRow) ? parseInt($productListSpacer.height(), 10) : 0;
		productPreview.getLowestPosition(currentPosition - positionOffset);
		$productPreview.attr({ class: 'js-productPreview productPreview is-showingProductPreview productPreview--' + (positionOffset + 1) + '-' + itemsPerRow}).css({ top: lowestPosition - containerOffset - openDialogOffset });

		// Center product previewer
		$('html,body').animate({
			scrollTop: lowestPosition - 200 - openDialogOffset,
		}, 200);

		// Approximate size of content first time - 44 is side padding
		if (estimatedHeight === undefined || estimatedHeight === 0) {
			if (itemsPerRow > 2) {
				// Images take up approx 5/12 of the width of their container.
				estimatedHeight = Math.floor((currentContWidth - 44) * (5 / 12));
			} else {
				// Mobile view take up double height.
				estimatedHeight = Math.floor((currentContWidth - 44) * 2);
			}
		}

		// Size previewer
		$slideCont.height(estimatedHeight);
		const afterPosition = ((thisRow + 1) * itemsPerRow) - 1 >= $productList.length ? $productList.length - 1 : ((thisRow + 1) * itemsPerRow) - 1;
		if (thisRow !== previousRow) $productList.eq(afterPosition).after($productListSpacer);
		$productListSpacer.css('height', estimatedHeight + 97);

		const updateHeight = () => {
			if (estimatedHeight !== Math.floor($slideCont.find('.productPreview-slide').height())) {
				estimatedHeight = Math.floor($slideCont.find('.productPreview-slide').height());
				$slideCont.height(estimatedHeight);
				$('.productList-container').find('.js-productListSpacer').css('height', estimatedHeight + 77);
			}
		};

		$.ajax({
			data: {
				product_previewer: true,
			},
			url: link,
			type: 'GET',
			dataType: 'JSON',
			success(data) {
				if (data.success) {
					$slideCont.html(data.preview);
					$slideCont.addClass('productPreview-content--loaded');
					ProductImages.init(true);

					if ($('#js-retracedMainScript')) $('#js-retracedMainScript').remove();

					const retracedScript = document.createElement('script');
					retracedScript.setAttribute('src', 'https://rtcd.me/shop-components.js');
					retracedScript.id = 'js-retracedMainScript';
					document.body.appendChild(retracedScript);

					RecentlyViewed.add(data.product);

					$('.rekaf').rekaf({
						useScreen: false,
						zIndex: 50,
						useHTML: true,
					});

					$slideCont.on('redils.updated', '.redils--product', () => {
						updateHeight();
					});

					clearTimeout(timeoutId);
					// Set timeout to coincide with 2.5s timeout of slider.
					timeoutId = setTimeout(() => {
						updateHeight();
					}, 2500);
				}
			},
		});

		// Reset for next click
		lowestPosition = 0;
		previousRow = thisRow;
	},
	closeProductPreview: () => {
		if (previewOpened) {
			clearTimeout(timeoutId);
			$productListContainer.find('.productList-previewing').removeClass('productList-previewing');
			$productPreview.removeClass('is-showingProductPreview');
			setTimeout(() => {
				// This is a hack but it seems like it's the only way to get style to completely disappear on resize...
				if ($('.js-productListSpacer').slice(1).length > 0) $('.js-productListSpacer').slice(1).remove();
			}, 20);
		}
	},
};

export default productPreview;
