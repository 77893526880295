import Map from './map';

const Stores = {
	init() {
		if ($('#js-map').length !== 0) Map.init();

		if ($('#js-storesFilter').length !== 0) {
			$('#js-storesFilter').rekaf({
				zIndex: 50,
			});

			$('#js-storesFilter').on('click', '.region', function () {
				const data = $(this).data('coords');
				const id = $(this).attr('id');

				$('#js-map').reppam('showCoords', data);
				$('#js-storesContainer').find('.storesItem').show().not(`.js-region-${id}`).hide();
			});
		}
	},
};

export default Stores;
