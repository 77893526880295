import store from '../shop/store';
import Validate from './validate';
import { changeCountry, changeAddressInput } from '../shop/connect';
import { paymentMethodsMap } from '../config';

const ChangeCountry = {
	init: ($parent, giftCertificate = false) => {
		const $scope = $parent || $(document);

		$scope.on('change', '#giftCert_address_country,#checkout_address_country,#checkout_shippingAddress_country,#checkout_shippingAddress_useShipping', function () {
			let $this = $(this);

			// No effect if we are using shipping address.
			if ($this.attr('id') === 'checkout_address_country' && $('#checkout_shippingAddress_useShipping').is(':checked')) return;

			// Switch country back to billing country.
			if ($this.attr('id') === 'checkout_shippingAddress_useShipping') {
				if (!$('#checkout_shippingAddress_useShipping').is(':checked')) {
					Validate.saveField($this);
					$this = $('#checkout_address_country');
				} else {
					Validate.saveField($this);
					$this = $('#checkout_shippingAddress_country');
				}
			}

			Validate.saveField($this);
			changeAddressInput('address', 'country', $this.val());

			$('#js-shipping').hide();
			$('#js-payment').hide();

			$('#checkout_order_proceed').prop('disabled', true);

			changeCountry($this.val(), giftCertificate)
				.then((response) => {
					$('#checkout_order_proceed').prop('disabled', false);

					if (store.getState().adyenInitiated) window.location.href = window.location;

					const klarnaIsSelected = paymentMethodsMap.klarnaCheckout.indexOf(response.selection.paymentMethod) !== -1;
					if (response?.selection?.pluginFields?.paymentHTML && $('#js-klarnaCheckout').html() === '') {
						$('#js-klarnaCheckout').html(response.selection.pluginFields.paymentHTML);
					}

					if (klarnaIsSelected) {
						$('#js-addressFormContainer,#js-addressFormContainer-finish').hide();
						$('#js-klarnaCheckout').show();
					} else {
						$('#js-klarnaCheckout').hide();
						$('#js-addressFormContainer,#js-addressFormContainer-finish').show();
					}

					return response;
				})
				.catch(() => {
					$('#checkout_order_proceed').prop('disabled', false);
				});
		});
	},
};

export default ChangeCountry;
