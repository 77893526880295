const Embed = {
	init($scope) {
		$scope = $scope || $(document);

		let $mainEl;
		let animationPaused = false;

		$scope.on('click', '.js-filmContainer', function (e) {
			e.preventDefault();

			const $this = $(this);
			const $slider = $this.closest('.redils-cont');
			let src = $this.data('film-src');
			const contWidth = $this.width();
			const width = $this.data('film-width');
			const height = $this.data('film-height');

			src += `${src.indexOf('?') === -1 ? '?' : '&'}autoplay=1`;
			$mainEl = $this.clone(true);

			$this.html(`<iframe width="${contWidth}" height="${((height / width) * contWidth)}" src="${src}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);

			if ($slider.length > 0) {
				// Is in slider.
				$slider.find('.redils').redils('pauseAnimation');
				// $slider.find('.redils-controls').fadeOut(200);
				animationPaused = true;

				$slider.find('.redils').off('redils.interaction').on('redils.interaction', function () {
					if (animationPaused) {
						$slider.find('.redils-controls').show();
						$slider.find('.focused .imageOverlay').show();
						$slider.find('.focused .js-filmContainer').replaceWith($mainEl);
						if ($(this).data('auto') !== '') {
							$slider.find('.redils').redils('resumeAnimation');
						}
						animationPaused = false;
					}
				});
			}
		});
	},
};

export default Embed;
