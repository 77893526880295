import { subscribeToNewsletter } from '../shop/connect';
import { getCookie } from '../utils/dom';

const Newsletter = {
	init: () => {
		const $form = $('#popup_newsletter,#module_newsletter,#footer_newsletter');

		$form.on('submit', function (e) {
			e.preventDefault();

			const $input = $(this).find('#popup_newsletter_email,#module_newsletter_email,#footer_newsletter_email');
			const email = $input.val();
			const interest = $(this).serializeArray().filter((input) => /(fe)?male/.test(input.name)).map((input) => input.name.match(/(fe)?male/)[0]);

			if (!/\S/.test(email) || email.indexOf('@') === -1) {
				$form.find('.newsletter').addClass('is-error');
			} else {
				subscribeToNewsletter({ email, interest })
					.then((response) => {
						if (response.success) {
							if (typeof window.va !== 'undefined' && response.id) {
								if (!getCookie('_vaI')) window.va('setContactId', response.id);
							}

							$input.val('').prop('checked', false).prop('placeholder', response.message);

							setTimeout(() => {
								if ($input.closest('.js-cookieContainer').length > 0) {
									$input.closest('.js-cookieContainer').find('.js-setCookie').trigger('click');
								} else {
									$input.prop('placeholder', $input.data('placeholder'));
								}
							}, 2000);
						} else {
							$input.addClass('is-error');
						}
						return response;
					})
					.catch(() => console.log('DEBUG: newsletter did not subscribe'));
			}
		});

		$form.on('focus change', '#popup_newsletter_email,#module_newsletter_email,#footer_newsletter_email', function() {
			$(this).closest('.is-success, .is-error, .is-registered').removeClass('is-success is-error is-registered');
			$(this).prop('placeholder', $(this).data('placeholder'));
		});
	},
};

export default Newsletter;
