import Common from '../common';
import Slider from '../content/slider';

const Modal = {
	init(content, modalClass) {
		const $body = $('body');
		const $modalContainer = $('#js-modalContainer');
		const $modalContent = $modalContainer.find('.modal-inner-content');
		const modalClasses = $modalContainer.attr('class');

		if (content === undefined) {
			$(document).on('click', '.js-modal', function (e) {
				e.preventDefault();
				$modalContainer.show();
				setTimeout(() => {
					$modalContainer.addClass('modal--showing');
				}, 20);
				$body.data('modal-showing', true).addClass('is-modalOpen');

				$.ajax({
					url: Common.getOriginAndLocale() + $(this).attr('href'),
					data: {
						modal: true,
					},
					type: 'GET',
					dataType: 'JSON',
					success(data) {
						$modalContent.html(data.content);
						$modalContainer.addClass('modal--width-' + data.width);
						Slider.init();
					},
				});
			});
		} else {
			$modalContainer.show();
			setTimeout(() => {
				$modalContainer.addClass('modal--showing');
			}, 20);
			$body.data('modal-showing', true).addClass('is-modalOpen');
			$modalContent.html(content);
			$modalContainer.addClass('modal--' + modalClass);
		}

		$modalContainer.on('click', '.modal-screen,.modal-inner-close', (e) => {
			e.preventDefault();
			e.stopPropagation();
			$modalContainer.removeClass().addClass(modalClasses).hide();
			$body.data('modal-showing', false).removeClass('is-modalOpen');
		});

		$(window).on('keydown', (e) => {
			if ($body.data('modal-showing')) {
				if (e.keyCode === 27) {
					// Esc
					$modalContainer.removeClass().addClass(modalClasses).hide();
					$body.data('modal-showing', false).removeClass('is-modalOpen');
				}
			}
		});
	},
};

export default Modal;
