const ShippingAddress = {
	init: ($parent) => {
		const $scope = $parent || $(document);

		$scope.on('change', '#checkout_shippingAddress_useShipping', function () {
			if ($(this).is(':checked')) {
				$(this).closest('.addressForm-part').addClass('addressForm-part--selected');
			} else {
				$(this).closest('.addressForm-part').removeClass('addressForm-part--selected');
			}
		});

		$('#checkout_shippingAddress_useShipping:checked').closest('.addressForm-part').addClass('addressForm-part--selected');
	},
};

export default ShippingAddress;
