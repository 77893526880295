import Filter from './filter';
import Autoload from './autoload';

const Category = {
	init() {
		Filter.init();
		Autoload.init('filterItems', 'js-nextButton');
	},
};

export default Category;
