import { getCookie } from '../utils/dom';
import { facebookCountry } from '../utils/string';
import Common from '../common';
import Autoload from '../category/autoload_search';
import filterData from '../category/get_filter_data';

const Search = {
	formatCompareString: (str) => str.replace(/[/\\´`"',.|!_-]/g, '').toLowerCase(),
	init: () => {
		const $body = $('body');
		const { country, market } = getCookie('store', true) || '{}';
		const favorites = getCookie('favorites') || '[]';
		const favoritesArray = JSON.parse(decodeURIComponent(favorites));
		let page = 1;
		let totalPages = 1;
		let searchMatch = [];
		let searchObject = {};
		let limit = 40;
		let debounceTimer;

		filterData.init((data) => {
			searchObject = $.extend({}, data);
		});
		if (searchObject?.filter?.settings?.limit) limit = searchObject.filter.settings.limit;

		Autoload.init('js-searchItems', 'js-nextButtonSearch');

		const triggerSearchEvent = (query, list, cacheLookup, cache) => {
			const uniqueIds = list.map((id) => (
				cacheLookup[id] && cache[cacheLookup[id]] ? `6/${facebookCountry({ country })}${cache[cacheLookup[id]].uid}` : false
			)).filter((uid) => uid);

			if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
			window.dataLayer.push({ values: null });
			window.dataLayer.push({
				event: 'Search',
				values: {
					currency: window.storeSettings.currency,
					content_ids: uniqueIds,
					search_string: query,
				},
			});

			window.dataLayer.push({ values: null });
			window.dataLayer.push({
				event: 'SearchTikTok',
				values: {
					query,
				},
			});

			window.dataLayer.push({ crto: null });
			window.dataLayer.push({
				event: 'crto_listingpage',
				crto: {
					email: Common.getCookie('accepted') || '',
					products: uniqueIds,
				},
			});
		};

		$('#js-clearSearch').on('click', function (e) {
			e.preventDefault();
			if ($(this).next().val() === '') {
				$body.removeClass('is-search');
			} else {
				$(this).next().val('');
				$('#js-searchItems').html('');
			}
		});

		$('#js-toggleSearch').on('click', function (e) {
			e.preventDefault();
			const $parent = $(this).parent();
			$body.toggleClass('is-search');

			if (!$parent.find('input').is(':focus')) {
				$parent.find('input').focus();
				setTimeout(() => {
					$parent.find('input').focus();
				}, 100);
				// Hack to make Safari re-focus on search field.
				$parent.find('input')[0].setSelectionRange(0, 0);
			}

			filterData.init((data) => {
				searchObject = $.extend({}, data);
				if (data?.filter?.settings?.limit) limit = data.filter.settings.limit;
			});
		});

		$('.js-searchForm').on('submit', function (e) {
			const currentSearchValue = $(this).find('input').val();
			const emptyReg = /\S/;

			if (!emptyReg.test(currentSearchValue)) {
				e.preventDefault();
				// Is empty - close search
				$body.removeClass('is-search');
				$('#js-searchItems').empty();
			}
		});

		$('#js-searchInput').on('keyup', (e) => {
			const value = $(e.currentTarget).val();

			if (value === '') {
				$('#js-searchItems').html('');
				return;
			}

			value.split(' ').forEach((singleValue, i) => {
				const currentSearchMatch = [];
				let reducedSearchMatch = [];

				if (singleValue === '') return;
				singleValue = Search.formatCompareString(singleValue);

				searchObject.searchValues.forEach((word, index) => {
					if (!word) word = '';
					if (typeof word !== 'string') word = word.toString();
					const keys = searchObject.searchKeys[index] && searchObject.searchKeys[index].split('::');
					let products = [];
					let rating = 1;
					word = Search.formatCompareString(word);

					if (keys && word.indexOf(singleValue) !== -1) {
						// Direct match with start character
						if (keys.length > 1) {
							// is filter
							if (!searchObject.filter.filter[keys[0]]) return;
							if (!searchObject.filter.filter[keys[0]][keys[1]]) return;
							products = searchObject.filter.filter[keys[0]][keys[1]].slice(0);
							rating = products.length;
						} else {
							// is product
							products = keys.slice(0);
						}

						if (word.indexOf(singleValue) === 0) {
							rating *= 10;
						}

						currentSearchMatch.push({ word, index, rating, products });
					}
				});

				if (currentSearchMatch.length === 0) return;

				currentSearchMatch.sort((a, b) => a.rating - b.rating);
				reducedSearchMatch = currentSearchMatch.reduce((productList, product) => [...productList, product.products], []);
				reducedSearchMatch = Common.unique(reducedSearchMatch);

				if (i === 0) {
					searchMatch = reducedSearchMatch.slice(0);
				} else {
					searchMatch = Common.intersect(searchMatch, reducedSearchMatch);
				}
			});
			searchMatch = Common.unique(searchMatch);

			page = 1;
			totalPages = Math.ceil(searchMatch.length / limit);
			$('#js-nextButtonSearch').toggle(totalPages > page);
			if ($(window).scrollTop() !== 0) {
				$('body,html').animate({
					scrollTop: 0,
				}, 200);
			}

			$('#js-search').refilt('renderProducts', {
				itemContId: 'js-searchItems',
				appendItems: true,
				pages: totalPages,
				filteredBy: {
					page,
				},
				eachItemAttrs: ($this, currentItem) => {
					currentItem.favoriteSelected = '';
					if (favoritesArray.indexOf(parseInt(currentItem.id, 10)) !== -1) {
						currentItem.favoriteSelected = ' productList-favorites--selected';
					}
					if (currentItem.price.soldout) {
						currentItem.productSoldOut = ' productList--soldOut';
					}
					return currentItem;
				},
			}, searchMatch.slice((limit * (page - 1)), (limit * page)), searchObject.filter);

			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(() => {
				triggerSearchEvent(value, searchMatch.slice((limit * (page - 1)), (limit * page)), searchObject.filter.productIds, searchObject.filter.products);
			}, 300);
		});

		$('#js-nextButtonSearch').on('click', function (e) {
			e.preventDefault();

			if (page >= totalPages) {
				$(this).hide();
				return false;
			}
			page += 1;

			$('#js-search').refilt('renderProducts', {
				debug: true,
				itemContId: 'js-searchItems',
				appendItems: true,
				pages: totalPages,
				filteredBy: {
					page,
				},
				eachItemAttrs: ($this, currentItem) => {
					currentItem.favoriteSelected = '';

					if (favoritesArray.indexOf(parseInt(currentItem.id, 10)) !== -1) {
						currentItem.favoriteSelected = ' productList-favorites--selected';
					}

					if (currentItem.price && currentItem.price.soldout) {
						currentItem.productSoldOut = ' productList--soldOut';
					}

					currentItem.sizes = Object.keys(currentItem.items).filter((item) => (
						currentItem.items[item].stockByMarket[market] !== 0
					)).map((item) => `<button class="productList-size" type="button" value="${currentItem.items[item].item}">${currentItem.items[item].name}</button>`).join('');

					return currentItem;
				},
			}, searchMatch.slice((limit * (page - 1)), (limit * page)), searchObject.filter);
		});

		$(window).on('keydown', (e) => {
			if ($body.hasClass('is-search') && e.keyCode === 27) {
				// Esc key pressed
				$body.removeClass('is-search');
				$('#js-searchItems').empty();
			}
		});

		$('#js-closeSearch').on('click', (e) => {
			e.preventDefault();
			$body.removeClass('is-search');
			$('#js-searchItems').empty();
			$('#js-searchInput').val('');
		});
	},
};

export default Search;
