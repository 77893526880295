import Slider from './slider';
import FlexImages from './flexImages';
import Embed from './embed';
import Margins from './margins';
import Stores from '../stores/stores';

const Content = {
	init() {
		Slider.init();
		FlexImages.init();
		Embed.init();
		Margins.init();
		Stores.init();

		$('.js-accordian').on('click', function () {
			$(this).toggleClass('is-open');
		});
	},
};

export default Content;
