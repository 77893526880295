import { addToBag } from '../shop/connect';

const ListingAddToBag = {
	init: () => {
		$(document).on('click', '.js-listingAddToBag button[type=button]', function (e) {
			e.preventDefault();
			e.stopPropagation();
			const currentItem = $(this).val();
			addToBag(currentItem);
		});
	},
};

export default ListingAddToBag;
