const Shipping = {
	init: ($scope) => {
		$scope = $scope || $(document);

		const updateWidgets = function () {
			const sendData = $(this).closest('form').serialize();
			const action = $(this).closest('form').attr('action');

			$.ajax({
				url: `${action}/ajax`,
				data: sendData,
				method: 'POST',
				dataType: 'JSON',
				success: (data) => {
					$('#js-selectionOrder').html(data.selection_order);
					$('#js-selectionErrors').html('');
				},
			});
		};

		$scope.on('change', '#js-shippingMethods input', updateWidgets);
	},
};

export default Shipping;
