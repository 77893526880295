import LazyLoad from 'vanilla-lazyload';

let lazyLoadInstance = null;

const loadImages = {
	init() {
		if (lazyLoadInstance) {
			lazyLoadInstance.update();
		} else {
			lazyLoadInstance = new LazyLoad({
				threshold: 500,
				elements_selector: '.js-lazy',
				callback_loaded: () => {
					$(window).trigger('imageLoaded.lazyload');
				},
			});
		}
	},
};

export default loadImages;
