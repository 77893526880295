const Common = {
	grid() {
		let gridShowing = false;

		// Hide/display grid
		$(window).on('keydown', (e) => {
			if (e.shiftKey && e.ctrlKey && (e.keyCode === 1 || e.keyCode === 65)) {
				// ctrl + shift + a
				$('#js-gridContainer').toggleClass('u-hidden');
				gridShowing = true;
			}
			if (gridShowing && e.charCode === 0) {
				// esc
				$('#js-gridContainer').addClass('u-hidden');
				gridShowing = false;
			}
		});
	},
	urlify(name) {
		let i;
		let url = name;

		// Replace diacritics
		const lookup = [
			/[\300-\306]/g, 'A', /[\340-\346]/g, 'a',
			/\307/g, 'C', /\347/g, 'c',
			/[\310-\313]/g, 'E', /[\350-\353]/g, 'e',
			/[\314-\317]/g, 'I', /[\354-\357]/g, 'i',
			/\321/g, 'N', /\361/g, 'n',
			/[\322-\330]/g, 'O', /[\362-\370]/g, 'o',
			/[\331-\334]/g, 'U', /[\371-\374]/g, 'u',
		];

		for (i = 0; i < lookup.length; i += 2) {
			url = url.replace(lookup[i], lookup[i + 1]);
		}

		// Replace whitespace
		url = url.replace(/\s+/g, '-');

		// Remove anything else
		url = url.replace(/[^a-z0-9_\-\.]+/gi, '');

		return url;
	},
	animFrame() {
		let lastTime = 0;
		const vendors = ['ms', 'moz', 'webkit', 'o'];

		for (let x = 0; x < vendors.length && !window.requestAnimationFrame; x += 1) {
			window.requestAnimationFrame = window[`${vendors[x]}RequestAnimationFrame`];
			window.cancelAnimationFrame = window[`${vendors[x]}CancelAnimationFrame`] || window[`${vendors[x]}CancelRequestAnimationFrame`];
		}

		if (!window.requestAnimationFrame) {
			window.requestAnimationFrame = (callback) => {
				const currTime = new Date().getTime();
				const timeToCall = Math.max(0, 16 - (currTime - lastTime));
				const id = window.setTimeout(() => { callback(currTime + timeToCall); }, timeToCall);

				lastTime = currTime + timeToCall;
				return id;
			};
		}

		if (!window.cancelAnimationFrame) {
			window.cancelAnimationFrame = (id) => {
				clearTimeout(id);
			};
		}
	},
	imagesLoaded: ($imgs, limit, callbackEnd, callbackHalf) => {
		const totalImages = $imgs.length;
		let ranOnce = false;
		let startTimer = new Date().getTime() + limit;
		const isImageLoaded = (img) => {
			if (!img.complete) { return false; }
			if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) { return false; }
			return true;
		};

		if (window.requestAnimationFrame === undefined) Common.animFrame();

		(function testImages() {
			let numLoaded = 0;

			$imgs.each(function () {
				if (isImageLoaded($(this)[0])) {
					numLoaded += 1;
				}
			});

			if (numLoaded > Math.floor(totalImages / 3) && !ranOnce && callbackHalf !== undefined) {
				callbackHalf();
				ranOnce = true;
			}

			if (numLoaded !== totalImages) {
				window.requestAnimationFrame(testImages);
			} else if (startTimer < new Date().getTime() && limit) {
				// Create inifinite loop until all images are loaded.
				callbackEnd();
				startTimer = new Date().getTime() + limit;
				window.requestAnimationFrame(testImages);
			} else {
				callbackEnd();
			}
		}());
	},
	touchList: {},
	touchClick: () => {
		if (Common.touchList.moved) return false;
		return (Math.abs(Math.abs(Common.touchList.startX) - Math.abs(Common.touchList.endX))) < 50 && (Math.abs(Math.abs(Common.touchList.startY) - Math.abs(Common.touchList.endY))) < 50;
	},
	touchListener: () => {
		const $html = $('html');

		if ('ontouchstart' in document.documentElement) {
			$html.removeClass('no-touch');
			$html.addClass('touch');
		}

		if ($html.hasClass('no-touch')) {
			$(window).on('touchstart.touchTest', () => {
				$html.removeClass('no-touch');
				$html.addClass('touch');
				$(window).off('touchstart.touchTest');
			});
		}

		// logging all touches on screen.
		$(window).on({
			touchstart: (e) => {
				Common.touchList.startX = e.originalEvent.targetTouches[0].clientX;
				Common.touchList.startY = e.originalEvent.targetTouches[0].clientY;
				Common.touchList.endX = e.originalEvent.targetTouches[0].clientX;
				Common.touchList.endY = e.originalEvent.targetTouches[0].clientY;
				Common.touchList.moved = false;
			},
			touchmove: (e) => {
				Common.touchList.endX = e.originalEvent.targetTouches[0].clientX;
				Common.touchList.endY = e.originalEvent.targetTouches[0].clientY;
				if (!Common.touchClick()) {
					// You have moved away but you might move back.
					Common.touchList.moved = true;
				}
			},
		});
	},
	validateForm: ($els, excludeWithClass) => {
		let errors = 0;
		let $password; // use for test against
		let $email; // use for test against
		const emptyReg = /\S/;
		const numbReg = /\D/;

		excludeWithClass = excludeWithClass || '';

		if ($els.find('input, textarea, select').length > 0) {
			$els = $els.find('input, textarea, select');
		}

		$els.each(function () {
			const $this = $(this);
			const val = $this.val();
			let error = 0;
			let name = $this[0].nodeName.toLowerCase();

			if (excludeWithClass !== '' && $this.hasClass(excludeWithClass)) return;

			if (name === 'input') {
				name = $this.attr('type');
			}

			switch (name) {
				case 'email':
					if ($email === undefined) {
						error = (val.indexOf('@') === -1 || val.indexOf('@') > val.lastIndexOf('.')) ? 1 : 0;
						console.log('DEBUG: error', error);
						$email = $this;
					} else {
						error = (val !== $email.val()) ? 1 : 0;
						console.log('DEBUG: error', error);
					}
					break;
				case 'password':
					if ($password === undefined) {
						error = (!emptyReg.test(val) || val === $this.attr('placeholder') || val.length < 8) ? 1 : 0;
						$password = $this;
					} else {
						error = (val !== $password.val()) ? 1 : 0;
					}
					break;
				case 'checkbox':
					error = (!$this.is(':checked')) ? 1 : 0;
					break;
				case 'select':
					error = (val === 0) ? 1 : 0;
					break;
				case 'number':
					error = (numbReg.test(val) || (!emptyReg.test(val) || val === $this.attr('placeholder')) || val < 1) ? 1 : 0;
					break;
				case 'hidden':
					error = 0;
					break;
				default:
					error = (!emptyReg.test(val) || val === $this.attr('placeholder')) ? 1 : 0;
					break;
			}

			if (error > 0) {
				$this.addClass('u-error').parent().addClass('u-error');
			} else {
				$this.removeClass('u-error').parent().removeClass('u-error');
			}

			errors += error;
		});

		return errors === 0;
	},
	iframeProportions: ($container, update, size) => {
		$container = $container || $(document);
		update = update || false;

		const $iframe = $container.find('iframe,video');
		if ($iframe.length === 0) { return; }
		if (window.requestAnimationFrame === undefined) Common.animFrame();

		const updateProportions = () => {
			$iframe.each((i, el) => {
				const $this = $(el);
				const naturalWidth = $this.attr('width');
				const naturalHeight = $this.attr('height');
				const ratio = naturalHeight / naturalWidth;
				const zoom = $this.data('zoom') ? $this.data('zoom') : 1;
				const currentWidth = $this.width();
				const parentWidth = $this.parent().width();
				const parentHeight = $this.parent().height();

				if (size === 'cover') {
					if (parentWidth * ratio >= parentHeight) {
						$this.width(parentWidth * zoom);
						$this.height(parentWidth * ratio * zoom);
					} else {
						$this.width(parentHeight * (1 / ratio) * zoom);
						$this.height(parentHeight * zoom);
					}
				} else if (size === 'contain') {
					if (parentWidth * ratio >= parentHeight) {
						$this.width(parentHeight * (1 / ratio));
						$this.height(parentHeight);
					} else {
						$this.width(parentWidth);
						$this.height(parentWidth * ratio);
					}
				} else {
					$this.height(currentWidth * ratio);
				}
			});
		};

		if (!update) {
			$(window).off('resize.iframeProportions').on('resize.iframeProportions', () => {
				window.requestAnimationFrame(updateProportions);
			});
		}

		updateProportions();
	},
	getOriginAndLocale: () => {
		const regex = /https?:\/\/[^/]*\/(\w{2}(?!\w))?/;
		const currentLoc = window.location.href;
		const matches = currentLoc.match(regex);
		return matches[0].replace(/\/$/, '');
	},
	getGrid: (grid, col, remove) => {
		/*
			relies on #js-gridContainer to give an accurate width of grid.
			grid = {
				columns: total columns,
				colWidth: px width of columns at normal size,
				gutWidth: px width of gutter at normal size
			}
		*/
		const gridWidth = $('#js-gridContainer').width();
		let fullWidth = 0;
		let width = 0;
		remove = remove || 0;

		col = col <= grid.columns ? col : grid.columns;
		fullWidth = (grid.columns * grid.colWidth) + ((grid.columns - 1) * grid.gutWidth); // full width
		width = (col * grid.colWidth) + ((col - 1) * grid.gutWidth); // width at full width.
		width = Math.floor((width / fullWidth) * (gridWidth - remove));

		return width;
	},
	unique: (a) => {
		// Optimized: jsperf test http://jsperf.com/hash-sieving/3
		// Returns an array with only unique values
		const na = [];
		lbl:for (let i = 0; i < a.length; i += 1) {
			for (let j = 0; j < na.length; j += 1) {
				if (na[j] === a[i])
					continue lbl;
			}
			na[na.length] = a[i];
		}
		return na;
	},
	intersect: (a, b) => {
		// Optimized: jsperf test http://jsperf.com/replace-function-or-several-replaces
		// Only choose items that are in both arrays (a and b)
		// Returns array
		const inter = [];
		const inA = {};
		let i = 0;

		for (i = 0; i < a.length; i += 1) {
			inA[a[i]] = true;
		}
		for (i = 0; i < b.length; i += 1) {
			if (inA[b[i]]) inter[inter.length] = b[i];
		}
		return inter;
	},
	setCookie: (cookieName, cookieValue, cookieDuration) => {
		cookieValue = cookieValue || 1;
		cookieDuration = cookieDuration || 31536e3; /* 1 year */

		const expires = `expires=${new Date(Date.now() + (cookieDuration * 1000))}; `;
		document.cookie = `${cookieName}=${(typeof cookieValue !== 'string' ? JSON.stringify(cookieValue) : cookieValue)}; ${expires}path=/`;
		return true;
	},
	getCookie: (cookieName) => {
		let cookieValue = null;
		document.cookie.split(';').forEach((cookie) => {
			if (cookie.indexOf(cookieName) !== -1) {
				cookieValue = cookie.trim().split('=').slice(-1)[0];
			}
		});
		return cookieValue;
	},
	deepClone: (o) => {
		const out = Array.isArray(o) ? [] : {};
		if (!isObject(o)) return out;
		Object.keys(o).forEach((key) => {
			const v = o[key];
			out[key] = isObject(v) ? deepClone(v) : v;
		});
		return out;
	},
};

export default Common;
