import Common from '../common';

const Popup = {
	displayShown: false,
	init() {
		if ($('#js-popup').length === 0) return;
		const $popup = $('#js-popup');
		const cookieValue = Common.getCookie($popup.data('name'));
		console.log('DEBUG: cookieValue', cookieValue);
		if (cookieValue === null || cookieValue === 'shown') return;

		const checkPopupInterval = setInterval(() => {
			if (!Popup.displayShown) {
				Popup.checkDisplay($popup);
			} else {
				clearInterval(checkPopupInterval);
			}
		}, 1000);
	},
	checkDisplay($popup) {
		if (new Date().getTime() > (parseInt($popup.data('trigger'), 10) * 1000) && window.location.href.indexOf('selection') === -1) {
			Popup.displayShown = true;
			$popup.addClass('modal--showing').show();
		}
	},
};

export default Popup;
