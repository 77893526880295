import Common from '../common';

const Slider = {
	init($scope) {
		$scope = $scope || $(document);

		$scope.find('.redils-responsive').redils({
			autoResize: true,
		});

		$scope.find('.redils-responsive').on('redils.imagesLoaded', () => {
			Common.iframeProportions($('.redils-responsive'), true);
		});
	},
};

export default Slider;
