import { changeQuantity } from '../shop/connect';

const Header = {
	init: () => {
		const $body = $('body');

		$('#js-mobileMenu').on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();

			// Add min height to window based on possible UI covering
			const innerHeight = window.innerHeight;
			const mainNaigationHeight = $('#js-mainNavigation').height();
			const topNavigationHeight = (!$body.hasClass('is-menuSticky') ? $('#js-topNavigation').height() : 0);

			if ($(this).parent().hasClass('mainNavigation-mobileSection--open')) {
				$(this).parent().removeClass('mainNavigation-mobileSection--open');
				$('#js-mobileMenu-links').find('.is-expanded').removeClass('is-expanded');
				$(this)
					.next()
					.removeAttr('style')
					.find('#js-overflow')
					.removeAttr('style');
			} else {
				$(this)
					.next()
					.css({ 'padding-bottom': 60 })
					.find('#js-overflow')
					.css({ 'max-height': (innerHeight - topNavigationHeight - mainNaigationHeight - 40) });
				$(this).parent().addClass('mainNavigation-mobileSection--open');
			}
		});

		if (window.Modernizr.touch) {
			$('#js-mobileMenu-links').on('click', '.js-openSubMenu', function (e) {
				if ($(window).width() <= 600 && !$(this).parent().hasClass('is-expanded')) {
					e.preventDefault();
					$('#js-mobileMenu-links').find('.is-expanded').removeClass('is-expanded');
					$(this).parent().addClass('is-expanded');
				}
			});
		}

		// Remove from basket
		$('#js-selectionHeader').on('click', '.js-removeItem', function (e) {
			e.preventDefault();

			changeQuantity($(this).data('line'), 0);
		});
	},
};

export default Header;
