import * as Sentry from '@sentry/browser';
import store from '../shop/store';
import { abandonedCart, initiateKlarna, initiateCaptcha, initiateIngrid, ingridUpdateMethod, purchase } from '../shop/connect';
import { getCookie } from '../utils/dom';
import { translateObjectData } from '../utils/locale';
import { paymentMethodsMap } from '../config';

const Continue = {
	init: ($parent, giftCertificate = false) => {
		const $scope = $parent || $(document);

		$scope.on('submit', '#js-selectionForm', async (e) => {
			e.preventDefault();

			// Set up abandoned cart tracking.
			abandonedCart()
				.then((response) => {
					if (response.success) {
						if (typeof window.va !== 'undefined' && response.id) {
							if (!getCookie('_vaI')) window.va('setContactId', response.id);
						}
					}
					return response;
				}).catch((err) => console.log('DEBUG: err', err));

			try	{
				if (store.getState().selection?.captcha) {
					await initiateCaptcha();
				}

				// Load in Ingrid/Klarna
				const klarnaInitiated = initiateKlarna()
					.then((response) => {
						const klarnaIsSelected = response?.data?.formHtml || paymentMethodsMap.klarnaCheckout.indexOf(response?.data?.selection?.paymentMethod) !== -1;
						if (response?.data?.formHtml && $('#js-klarnaCheckout').html() === '') {
							$('#js-klarnaCheckout').html(response.data.formHtml);
						}
						if (klarnaIsSelected) {
							$('#js-klarnaCheckout').show();
							$('#js-addressFormContainer,#js-addressFormContainer-finish').hide();
						} else {
							$('#js-klarnaCheckout').hide();
							$('#js-addressFormContainer,#js-addressFormContainer-finish').show();
						}

						return response;
					})
					.catch((err) => console.log('ERROR: Unable to initiate Klarna.', err));

				const ingridInitiated = initiateIngrid()
					.then((response) => {
						if (response?.data?.selection?.pluginFields?.shipwallet?.snippet && $('#js-ingrid').html() === '') {
							$('#js-ingrid').html(response.data.selection.pluginFields.shipwallet.snippet);

							window._sw((api) => { // eslint-disable-line no-underscore-dangle
								api.on('data_changed', (shippingMethod) => {
									ingridUpdateMethod(shippingMethod);
								});
							});
						}
						return response;
					})
					.catch((err) => console.log('ERROR: Unable to initiate Ingrid.', err));

				Promise.all([klarnaInitiated, ingridInitiated])
					.then(() => {
						if (store.getState()?.paymentReturn?.centraCheckoutScript && $('#js-centraCheckoutScript').html() === '') {
							$('#js-centraCheckoutScript').html(`<script type="text/javascript">${store.getState()?.paymentReturn?.centraCheckoutScript}</script>`);
						}
						$('#js-summary').show();
						$('#js-shipping').show();
						$('#js-payment').hide();
						$('#js-selectionForm').hide();
						return 1;
					})
					.catch(() => console.log('ERROR: Unable to initiate Centra Script'));
			} catch (err) {
				if (String(err).indexOf('captcha')) {
					Sentry.captureMessage('Captcha failed');
					$('#js-shipping').html('<div class="u-error">reCAPTCHA V3 failed to verify your purchase. Please try again or contact customer service.</div>');
					$('#js-shipping').show();
				} else {
					console.log('ERROR: was unable to initiate purchase');
				}
			}
		});

		$scope.on('click', '.js-editSection', function () {
			const $this = $(this);
			const whichSection = $this.data('section');

			if (whichSection === 'address') {
				$('#js-summary').hide();
				$('#js-shipping').hide();
				$('#js-payment').hide();
				$('#js-selectionForm').show();
			} else if (whichSection === 'shipping') {
				$('#js-summary').show();
				$('#js-shipping').show();
				$('#js-payment').hide();
				$('#js-editShipping').hide();
				$('#js-selectionForm').hide();
			}
		});

		$scope.on('click', '#js-continueConfirm', (e) => {
			e.preventDefault();
			const { storeSettings, languages, selection, ingridShippingMethod } = store.getState();
			const setup = { ...storeSettings, languages };

			window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			window.dataLayer.push({
				event: 'add_shipping_info',
				ecommerce: {
					currency: setup.currency,
					value: selection.totals.grandTotalPriceAsNumber,
					coupon: selection.discounts.vouchers.reduce((acc, obj) => [...acc, obj.voucher], []).join(', '),
					shipping_tier: ingridShippingMethod?.category_name || '', // eslint-disable-line camelcase
					items: selection.items.map((item) => {
						const localizedItem = translateObjectData(setup, item);
						const itemCategory = localizedItem.category && localizedItem.category.split('/').reduce((acc, category, j) => ({ ...acc, [`item_category${j > 0 ? j + 1 : ''}`]: category }), {});

						return {
							item_id: localizedItem.sku,
							item_name: localizedItem.name,
							item_brand: localizedItem.brand,
							...(itemCategory || {}),
							item_variant: localizedItem.variant,
							price: localizedItem.priceEachAsNumber,
							quantity: localizedItem.quantity,
						};
					}),
				},
			});

			// Complete payment.
			purchase(giftCertificate)
				.then((response) => {
					$('#js-summary').show();
					$('#js-shipping').hide();
					$('#js-payment').show();
					$('#js-selectionForm').hide();
					$('#js-editShipping').show();
					$('#giftCert_order_proceed').hide();

					return response;
				})
				.catch(() => {
					console.log('ERROR: was unable to initiate purchase');
				});
		});
	},
};

export default Continue;
