import { addToBag } from '../shop/connect';
import Common from '../common';

const Purchase = {
	init: ($scope) => {
		$scope = $scope || $(document);

		let goToCheckout = false;

		$scope.on('change', '.js-chooseSize input', function () {
			const $buyButton = $(this).closest('.js-productForm').find('.js-buyButton');

			$buyButton.addClass('is-buyable').removeClass('is-missingSize is-forcedSizeMissing').find('span').text($buyButton.data('orig-text'));
			$(this).closest('.js-chooseSize').removeClass('is-sizeError');
			if (goToCheckout) {
				$(this).closest('.js-productForm').off('click', '.js-buyButton');
				$buyButton.removeClass('is-checkoutButton');
				goToCheckout = false;
			}
		});

		$scope.on({
			mouseenter() {
				if (!$(this).closest('.js-productForm').find('input').is(':checked') && $(this).hasClass('is-buyable')) {
					// If size is not checked
					$(this).find('span').text($(this).data('hover-text')).removeClass('is-buyable').addClass('is-missingSize');
				}
			},
			mouseleave() {
				if ($(this).hasClass('is-missingSize') && !$(this).hasClass('is-forcedSizeMissing')) {
					$(this).find('span').text($(this).data('orig-text')).removeClass('is-missingSize').addClass('is-buyable');
				}
			},
		}, '.js-buyButton');

		$scope.off('submit').on('submit', '.js-productForm', function (e) {
			e.preventDefault();

			const $this = $(this);
			const $buyButton = $this.find('.js-buyButton');
			let currentItem = $this.find('input:checked').val();

			if (!$this.find('input:checked') && $this.find('input').length > 1) {
				$this.find('.rekaf').rekaf('open');
				return;
			}
			if ($this.find('input:checked').length === 0 && $this.find('input[name="addtocart[item]"]').length === 1) {
				currentItem = $this.find('input[name="addtocart[item]"]').val();
			}

			$buyButton.find('span').text($buyButton.data('co-text'));
			goToCheckout = true;
			$buyButton.addClass('is-checkoutButton');

			$this.on('click', '.js-buyButton', (ev) => {
				ev.preventDefault();
				ev.stopPropagation();
				window.location = `${Common.getOriginAndLocale()}/selection`;
			});

			addToBag(currentItem);
		});
	},
};

export default Purchase;
