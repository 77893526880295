import Common from '../common';

const $mainNavigation = $('#js-mainNavigation');
const $pushNotificationContainer = $('.pushNotificationContainer');
const $body = $('body');
const cutoff = 250;
let startSticky = 175;
let sticky = false;
let top = 0;

const Sticky = {
	init() {
		if ($pushNotificationContainer.length === 0 || $mainNavigation.length === 0) return;

		$(window).on({
			scroll: () => {
				window.requestAnimationFrame(Sticky.test);
			},
			resize: () => {
				window.requestAnimationFrame(Sticky.settings);
			},
		});

		Sticky.settings();
	},
	settings() {
		Sticky.measurements();
		Common.imagesLoaded($('img'), false, () => {
			Sticky.measurements();
		});

		Sticky.test();
	},
	measurements() {
		if ($('#js-mobileMenu').is(':visible')) {
			// Mobile.
			startSticky = $mainNavigation.offset().top;
		} else {
			startSticky = $mainNavigation.find('.mainNavigation-fixedContainer').offset().top;
		}
	},
	test() {
		top = $(window).scrollTop();

		// Only run up to a certain height from top.
		if ($mainNavigation.data('forced') || (top > cutoff && $mainNavigation.hasClass('mainNavigation--sticky'))) return;

		// Upon reaching the shortest height set float left - class + css
		if (top > startSticky && sticky === false) {
			$body.addClass('is-menuSticky');
			Sticky.set();
		} else if (top <= startSticky && sticky === true) {
			$body.removeClass('is-menuSticky');
			Sticky.unset();
		}
	},
	set() {
		$mainNavigation.addClass('mainNavigation--sticky');
		sticky = true;
	},
	unset() {
		$mainNavigation.removeClass('mainNavigation--sticky');
		sticky = false;
	},
};

export default Sticky;
