import { translateObjectData } from '../utils/locale';
import { facebookCountry, generateEventId } from '../utils/string';

const Analytics = (selection, { currency, country, language }, languages) => {
	if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
	const uniqueEventID = generateEventId();

	window.dataLayer.push({ values: null });
	window.dataLayer.push({
		event: 'InitiateCheckout',
		event_id: uniqueEventID,
		values: {
			currency,
			content_type: 'product',
			contents: selection.items.map((item) => ({
				id: `${item.id.replace(/6\/\w{2}/, `6/${facebookCountry({ country })}`)}`,
				quantity: `${item.quantity}`,
				item_price: `${item.priceEachAsNumber}`,
			})),
			num_items: `${selection.totals.totalQuantity}`,
			value: `${selection.totals.grandTotalPriceAsNumber}`,
		},
	});

	window.dataLayer.push({ values: null });
	window.dataLayer.push({
		event: 'InitiateCheckoutTikTok',
		values: {
			currency,
			contents: selection.items.map((item) => ({
				content_id: item.id,
				content_type: 'product',
				content_name: `${item.name}`,
				quantity: `${item.quantity}`,
				price: `${item.priceEachAsNumber}`,
			})),
			quantity: `${selection.totals.totalQuantity}`,
			value: `${selection.totals.grandTotalPriceAsNumber}`,
		},
	});

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'InitiateCheckoutGA',
		ecommerce: {
			checkout: {
				products: selection.items.map((item) => ({
					name: `${item.name}`,
					id: `${item.centraProduct}`,
					sku: `${item.sku}`,
					price: `${item.priceEachAsNumber}`,
					brand: `${item.brandName}`,
					category: `${(item.foldersName || '').trim()}`,
					variant: `${item.variantName}`,
					quantity: `${item.quantity}`,
				})),
			},
		},
	});

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'begin_checkout',
		ecommerce: {
			items: selection.items.map((item) => {
				const localizedItem = translateObjectData({ language, languages: { ...languages } }, item);
				const itemCategory = localizedItem.category && localizedItem.category.split('/').reduce((acc, category, j) => ({ ...acc, [`item_category${j > 0 ? j + 1 : ''}`]: category }), {});

				return {
					item_name: localizedItem.name,
					item_id: item.sku,
					price: item.priceEachAsNumber,
					currency,
					item_brand: localizedItem.brand,
					...(itemCategory || {}),
					item_variant: localizedItem.variant,
					quantity: `${item.quantity}`,
				};
			}),
		},
	});
};

export default Analytics;
