import Common from '../common';

const triggerOffset = 500;
let $filterItems = $('#filterItems');
let $nextButton = $('#js-nextButton');
let startAutoload;
let windowHeight;
let debounce = true;

const Autoload = {
	init(filterItemsID, nextButtonID) {
		if (filterItemsID) $filterItems = $(`#${filterItemsID}`);
		if (nextButtonID) $nextButton = $(`#${nextButtonID}`);

		if ($filterItems.length === 0 || $nextButton.length === 0) return;

		$(window).on({
			scroll: () => {
				window.requestAnimationFrame(Autoload.test);
			},
			resize: () => {
				window.requestAnimationFrame(Autoload.settings);
			},
		});

		Autoload.settings();
	},
	settings() {
		Autoload.measurements();

		Common.imagesLoaded($('img'), 1000, () => {
			Autoload.measurements();
		});

		Autoload.test();
	},
	measurements() {
		windowHeight = $(window).height();
		startAutoload = $filterItems.offset().top + $filterItems.height() - triggerOffset;
	},
	test() {
		// Only run if button isn't disabled.
		if ($nextButton.hasClass('u-disabled')) return;

		Autoload.measurements();
		const top = $(window).scrollTop() + windowHeight;

		// Upon reaching the shortest height set float left - class + css
		if (top > startAutoload && startAutoload !== undefined && debounce) {
			$nextButton.trigger('click');
			debounce = false;
			Autoload.settings();
			window.setTimeout(() => {
				debounce = true;
			}, 1000);
		}
	},
};

export default Autoload;
