const Margins = {
	init() {
		const $cmsModules = $('.js-cmsModule');

		Margins.adjust($cmsModules);

		$(window).on('resize', () => {
			window.requestAnimationFrame(() => {
				Margins.adjust($cmsModules);
			});
		});
	},
	adjust($els) {
		const startWidth = 1600;
		const currentWidth = $(window).width();
		const percentagePosition = currentWidth / startWidth > 1 ? 1 : currentWidth / startWidth;

		$els.each(function () {
			const $this = $(this);
			let originalMargins = [];

			if (!$this.data('original-margin')) {
				const topMargin = parseInt($this.css('margin-top'), 10);
				const bottomMargin = parseInt($this.css('margin-bottom'), 10);
				$this.data('original-margin', [topMargin, bottomMargin]);
			}

			originalMargins = $this.data('original-margin');

			if (originalMargins[0] > 20) {
				$this.css('margin-top', Math.floor((originalMargins[0] - 20) * percentagePosition));
			}

			if (originalMargins[1] > 20) {
				$this.css('margin-bottom', Math.floor((originalMargins[1] - 20) * percentagePosition));
			}
		});
	},
};

export default Margins;
