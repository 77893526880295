import { getCookie } from './dom';
import { deepMerge, deepClone } from './object';
import { decode } from './string';
import { useLanguageURLRoots } from '../config';

/*
	Assume translation is in setup.static
	Always return string
	translation input is unchanged if not found.
*/

const translate = (setup, translation, translationProperty) => {
	let translatedStr = translation;

	// translation of object not simple static text.
	if (typeof translation === 'object') {
		translationProperty = translationProperty || 'text';
		translatedStr = translation[translationProperty];

		if (translation.localized && translation.localized[setup.language]) {
			Object.keys(translation.localized[setup.language]).forEach((item) => {
				if (item === translationProperty) {
					translatedStr = translation.localized[setup.language][item];
				}
			});
		}

		return decode(translatedStr);
	}

	if (typeof translation === 'string') {
		translationProperty = translationProperty || 'statics';
		if (typeof setup[translationProperty][translation] === 'string') return decode(setup[translationProperty][translation]);
		const staticTranslation = setup[translationProperty][translation] && setup[translationProperty][translation].text;
		if (typeof staticTranslation === 'string') translatedStr = staticTranslation;
		if (typeof staticTranslation === 'object' && staticTranslation.text) translatedStr = staticTranslation.text;

		if (setup[translationProperty][translation] && setup[translationProperty][translation].localized && setup[translationProperty][translation].localized[setup.language]) {
			translatedStr = setup[translationProperty][translation].localized[setup.language].text || '';
		}
	}

	return decode(translatedStr);
};

const localize = (str, fallback) => {
	if (!window.texts) return '';
	const allStatics = window.texts;
	const statics = { ...allStatics.page, ...allStatics.main, ...allStatics.locale };
	str = String(str).toLowerCase();
	fallback = String(fallback).toLowerCase();
	const storeSettings = getCookie('store', true);
	if (statics[str]) return translate(storeSettings, statics[str]);
	if (statics[fallback]) return translate(storeSettings, statics[fallback]);
	return '';
};

const translateObjectData = (setup, objectData = {}) => {
	let returnObj = deepClone(objectData);
	if (Object.keys(setup.languages).length > 1 && returnObj.localized && returnObj.localized[setup.language]) {
		returnObj = deepMerge(returnObj, returnObj.localized[setup.language]);
	}
	return returnObj;
};

const translateObjectDefaultData = (setup, objectData = {}) => {
	let returnObj = deepClone(objectData);
	if (Object.keys(setup.languages).length > 1 && returnObj[setup.language]) {
		returnObj = deepMerge(returnObj.default, returnObj[setup.language]);
	} else {
		returnObj = deepClone(returnObj.default);
	}
	return returnObj;
};

const prefix = (setup, str = '/') => {
	str = str.replace(/^\/*/, '');
	if (Object.keys(setup.languages).length > 1 && !useLanguageURLRoots) {
		str = str.replace(`${setup.language}/`, '');
		return `/${setup.language}/${str}`;
	}
	return `/${str}`;
};

/*
	For testing purposes.

	const arr = [
		'/en_hello', // false
		'/en', // true
		'/en/', // true
		'/fr-ca/roller', // true
		'/fr-ca', // true
		'/fr-ca/', // true
		'/fr-ca-hello', // false
	];
	arr.map(str => getPrefix(str))
*/
const getPrefix = (str) => {
	if (!str) str = window.location.pathname;
	const matches = String(str.split('/').filter((a) => !!a)[0]).match(/^[a-z]{2}(-[a-z]{2})?$/);
	if (matches === null) return '';
	return `/${matches[0]}`;
};

/*
	For testing purposes.

	const arr = [
		'/en_hello', // false
		'/en', // true
		'/en/', // true
		'/fr-ca/roller', // true
		'/fr-ca', // true
		'/fr-ca/', // true
		'/fr-ca-hello', // false
	];
	arr.map(str => checkForLanguagePrefix(str))
*/
const checkForLanguagePrefix = (str) => /^[a-z]{2}(-[a-z]{2})?$/.test(str.split('/').filter((a) => !!a)[0]);

/*
	For testing purposes.

	const arr = [
		'/en_hello', // 'en_hello'
		'/en', // ''
		'/en/', // ''
		'/fr-ca/roller', // 'roller'
		'/fr-ca', // ''
		'/fr-ca/', // ''
		'/fr-ca-hello', // 'fr-ca-hello'
		'/fr-ca/hello', // 'hello'
		'/hello/uri', // 'hello/uri'
		'', // ''
		'/en/hello/ca-fr/uri/en', // 'hello/ca-fr/uri/en'
		'/ca-fr/hello/ca-fr/uri/en', // 'hello/ca-fr/uri/en'
	];
	arr.map(str => removeLanguagePrefix(str))
*/
const removeLanguagePrefix = (str) => {
	const splitStr = str.split('/').filter((a) => !!a);
	if (splitStr.length > 0) splitStr[0] = splitStr[0].replace(/^[a-z]{2}(-[a-z]{2})?$/, '');
	return splitStr.filter((a) => !!a).join('/');
};

const isEu = (country) => ['AX', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'DE', 'EE', 'FI', 'FO', 'FR', 'GI', 'GR', 'GG', 'HU', 'IS', 'IE', 'IM', 'IT', 'JE', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'MD', 'MC', 'ME', 'NL', 'PL', 'PT', 'RO', 'SE', 'SM', 'SK', 'SI', 'ES', 'CH', 'UA', 'VA'].indexOf(country.toUpperCase()) !== -1;

const voyadoLocale = (setup = {}) => {
	const c = setup.country.toUpperCase();
	const feeds = { DK: 'en-DK', DE: 'de-DE', AT: 'de-AT', SE: 'sv-SE', EU: 'en-MT', FI: 'en-FI', GB: 'en-GB', NO: 'nb-NO', US: 'en-US' };
	if (feeds[c]) return feeds[c];
	if (isEu(c)) return feeds.EU;
	return feeds.US;
};

export {
	localize,
	translate,
	translateObjectData,
	translateObjectDefaultData,
	prefix,
	getPrefix,
	checkForLanguagePrefix,
	removeLanguagePrefix,
	voyadoLocale,
};
