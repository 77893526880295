import { urlRoots } from '../config';

const convertToLatin = (str) => {
	if (!str) return '';
	if (typeof str !== 'string') str = String(str);

	str = str.replace(/[^a-zà-ÿ0-9\-_+\s\p{P}]/gui, (s) => (
		s.normalize('NFKD').replace(/[^a-z]/gi, '')
	));

	return str;
};

let decoder;

const decode = (str = '') => {
	if (!str) str = '';
	if (typeof str !== 'string') str = String(str);

	decoder = decoder || document.createElement('div');
	const html = escape(str).replace(/%26/g, '&').replace(/%23/g, '#').replace(/%3B/g, ';');
	decoder.innerHTML = html;

	return unescape(decoder.textContent);
};

const andLanguages = (language) => {
	if (!language) return '';
	language = language.toUpperCase();
	const and = { EN: 'and', SQ: 'dhe', EU: 'eta', BE: 'і', BS: 'i', BG: 'и', CA: 'i', HR: 'i', CS: 'a', DA: 'og', NL: 'en', ET: 'ja', FI: 'ja', FR: 'et', GL: 'e', DE: 'und', EL: 'και', HU: 'és', IS: 'og', GA: 'agus', IT: 'e', LV: 'un', LT: 'ir', MK: 'и', MT: 'u', NO: 'og', PL: 'i', PT: 'e', RO: 'și', RU: 'и', SR: 'и', SK: 'a', SL: 'in', ES: 'y', SV: 'och', UK: 'і', CY: 'ac', YI: 'און' };
	if (!and[language]) return '';
	return and[language];
};

// 1:1 with PHP script.
const urlifyAllowAccents = (str = '', replacer = '-', tolower = true, language = 'EN') => {
	str = str.replace('&', andLanguages(language));
	str = str.replace(/[/\\´`"',.|!]/g, '');
	str = str.replace(/[^\u00E0-\u017E\u1EA4\u04D1\u1EC7\u1EDF\u0219\w]/gi, replacer);
	str = str.replace(/[\u00F7\u00FE]/gi, '');
	str = str.replace(/--+/g, '-');
	str = str.replace(/^-/, '');
	str = str.replace(/-$/, '');

	if (tolower) return str.toLowerCase();
	return str;
};

const urlify = (name, replacer = '-') => {
	// Replace diacritics
	name = convertToLatin(name);

	// Replace whitespace
	name = name.trim().replace(/\s+/g, replacer);

	// Remove anything else
	name = name.replace(/[^a-z0-9_-]+/gi, '').toLowerCase();

	return name;
};

const cleanEmail = (email) => {
	// Replace diacritics
	email = convertToLatin(email);

	// Replace whitespace
	email = email.replace(/\s+/g, '');

	// Remove anything else
	email = email.toLowerCase().replace(/[^a-z0-9_\-.@]+/g, '');

	return email;
};

const upperCaseFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const lowerCaseFirst = (string) => string.charAt(0).toLowerCase() + string.slice(1);

const deCamel = (string) => {
	const newString = String(string).replace(/[a-z0-9](?=[A-Z])/g, (v) => `${v} `);
	return upperCaseFirst(newString);
};

// This Is Title Case
const titleCase = (string) => {
	const newString = String(string).toLowerCase().replace(/(\s|-|_)\S/g, (v) => v.toUpperCase());
	return upperCaseFirst(newString);
};

// This is sentence case
const sentenceCase = (string) => {
	const newString = String(string).toLowerCase().replace(/(\s|-|_)/g, ' ');
	return upperCaseFirst(newString);
};

// ThisIsPascalCase
const pascalCase = (string) => titleCase(string).replace(/(\s|-|_)/g, '');

// thisIsCamelCase
const camelCase = (string) => lowerCaseFirst(pascalCase(string));

const applyVAT = (setup, price, currency = false) => {
	if (!currency) currency = setup.currency;
	const countryData = setup.countries[setup.country];
	if (typeof price === 'object' && price[currency]) price = price[currency];
	if (typeof price === 'object' && price.price) price = price.price;
	if (countryData.incVAT) return (parseFloat(price) * ((countryData.incVAT / 100) + 1)).toFixed(2);
	if (countryData.exVAT) return (parseFloat(price) / ((countryData.exVAT / 100) + 1)).toFixed(2);
	return price;
};

const formatPrice = (setup, price, currency = false, vatApplied = false) => {
	if (!currency) currency = setup.currency;
	if (!vatApplied) price = applyVAT(setup, price, currency);
	const currencyData = setup.currencies[currency];
	const floatedPrice = parseFloat(price).toString();
	let intPrice = parseInt(price, 10).toString();
	let decimals = '00';
	if (floatedPrice.indexOf('.') !== -1) decimals = floatedPrice.slice(floatedPrice.indexOf('.') + 1);
	if (decimals.length === 1) decimals = `${decimals}0`;
	if (intPrice.length > 3 && currencyData.thousandsSeparator) intPrice = `${intPrice.slice(0, -3)}${currencyData.thousandsSeparator}${intPrice.slice(-3)}`;
	return `${currencyData.prefix}${intPrice}${parseInt(currencyData.decimals, 10) > 0 ? currencyData.decimalsSeparator : ''}${parseInt(currencyData.decimals, 10) > 0 ? decimals.slice(0, 2) : ''}${currencyData.suffix}`;
};

/*
let priceExamples = [
	'12 345.67 CAD',
	'99 999.99 CHF',
	'123 DKK',
	'99 999.99 EUR',
	'£ 99 999.99',
	'99 999.99 JPY',
	'0.99 NOK',
	'99 999 SEK ',
	'99 999.99 USD',
]
*/

const formatPriceByExample = (priceExample = '0.00', amount) => (
	String(priceExample).replace(/((\d+\s)?\d+)([.,]\d{2})?/, (match, hundreds, thousands, decimals) => {
		let priceReturn = '';
		let decimalsReturn = '';
		let accountForNoDecimals = 0;

		if (typeof decimals !== 'undefined' && /\.\d+$/.test(String(amount))) decimalsReturn += `${decimals.slice(0, 1)}${parseFloat(amount - parseInt(amount, 10)).toFixed(2).slice(-2)}`;
		else if (typeof decimals !== 'undefined') decimalsReturn += decimals.replace(/\d/g, '0');
		else if (typeof decimals === 'undefined' && parseFloat(amount % 1) !== 0) accountForNoDecimals = 1;

		if (typeof thousands !== 'undefined' && amount > 999) priceReturn += `${parseInt(amount / 1000, 10)} `;

		if (typeof hundreds !== 'undefined') priceReturn += `${parseInt(amount + accountForNoDecimals, 10) % 1000}`;

		return `${priceReturn}${decimalsReturn}`;
	})
);

const fieldValidation = (value, type) => {
	if (type === 'address2' || type === 'company') return false;
	if (value === undefined) return '';

	if (!/\S/.test(value)) return 'Empty';

	if (type.indexOf('email') !== -1 && (value.length < 6 || value.indexOf('@') < 1 || value.lastIndexOf('.') < 2 || value.lastIndexOf('.') === value.length - 1)) {
		return 'EmailIncorrect';
	}

	if (type.indexOf('password') !== -1 && value.trim().length < 8) {
		return 'PasswordIncorrect';
	}

	if (type.indexOf('phone') !== -1 && value.length < 6) {
		return 'PhoneIncorrect';
	}

	if (type.indexOf('state') !== -1 && !value) {
		return 'StateIncorrect';
	}

	if (type.indexOf('terms') !== -1 && !value) {
		return 'TermsIncorrect';
	}

	return false;
};

const cleanInternalLinks = (link) => {
	if (link === undefined) return '';
	if (typeof link !== 'string') link = String(link);

	urlRoots.forEach((base) => {
		if (link.indexOf(base) !== -1 && !/^mailto/.test(link)) {
			link = link.replace(/^(https?:)?\/\/[^/]+?\//, '/');
		}
	});

	// Ensure link isn't anchor/external
	if (!/^#/.test(link) && !/^(https?|mailto|tel)/.test(link)) {
		// Remove possible language looking part.
		if (/^\/\w{2}\//.test(link)) link = link.slice(3);
		if (!/^\//.test(link)) link = `/${link}`;
		if (/\/$/.test(link) && link.length > 1) link = link.slice(0, -1);
	}

	return link;
};

/*
[
	'#E53',
	'#8000d7',
	'#8000d7CD',
	'rgb(1,0,124)',
	'rgba(123,45,9,0.7)',
].map(getRGB);
*/

const getRGB = (colorCode) => {
	if (colorCode.indexOf('#') === 0) {
		if (colorCode.length === 4) return colorCode.match(/\w/g).map((x) => parseInt(`${x}${x}`, 16));
		return colorCode.match(/\w\w/g).map((x, i) => (i === 3 ? Math.round((parseInt(x, 16) / 255) * 100) / 100 : parseInt(x, 16)));
	}
	if (colorCode.indexOf('rgb') === 0) return colorCode.match(/[\d.]+/g).map((x, i) => (i === 3 ? parseFloat(x) : parseInt(x, 10)));
	// No match return black.
	return [0, 0, 0];
};

const hex2rgba = (hex, alpha = 1) => {
	const [r, g, b] = getRGB(hex);
	return `rgba(${r},${g},${b},${alpha})`;
};

const blackOrWhite = (hex) => {
	const [r, g, b] = getRGB(hex);
	return (r * 0.299) + (g * 0.587) + (b * 0.114) > 150 ? '#000' : '#FFF';
};

const atob = (str) => {
	if (typeof window !== 'undefined') return window.atob(str);
	return Buffer.from(str, 'base64').toString('binary');
};

const btoa = (str) => {
	if (typeof window !== 'undefined') return window.btoa(str);
	const buffer = str instanceof Buffer ? str : Buffer.from(str.toString(), 'binary');
	return buffer.toString('base64');
};

const createSha256Hash = (str) => {
	if (typeof crypto === 'undefined') return false;
	const utf8 = new TextEncoder().encode(String(str));
	return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((bytes) => bytes.toString(16).padStart(2, '0'))
			.join('');
		return hashHex;
	});
};

const splitNewLine = (str) => (str ? String(str).split(/\r?\n/) : []);

const wordCount = (str, amount) => {
	const words = str.split(' ');
	return [words.slice(0, amount).join(' '), words.slice(amount).join(' ')];
};

const createColors = (str) => (str ? splitNewLine(str).filter((c) => c).map((c) => String(c).split(',')).sort((a, b) => (a[1] > b[1] ? 1 : -1)) : []);

const generateEventId = () => `${`${Date.now()}`.slice(-8)}_${Math.random() * 1e10}`.replace('.', '');

const rootUrl = (str = '', i = '/') => {
	str = String(str);
	if (str.lastIndexOf(i) === -1) return str;
	return str.slice(0, str.lastIndexOf(i));
};

const baseName = (str = '', i = '/') => {
	str = String(str);
	if (str.lastIndexOf(i) === -1) return str;
	return str.slice(str.lastIndexOf(i) + 1);
};

const facebookCountry = (setup = {}) => {
	const feeds = ['de', 'dk', 'fi', 'se', 'nl'];
	if (setup.country && feeds.indexOf(setup.country.toLowerCase()) !== -1) return setup.country.toLowerCase();
	return 'se';
};

export {
	decode,
	convertToLatin,
	urlify,
	urlifyAllowAccents,
	cleanEmail,
	upperCaseFirst,
	lowerCaseFirst,
	deCamel,
	pascalCase,
	camelCase,
	titleCase,
	sentenceCase,
	applyVAT,
	formatPrice,
	formatPriceByExample,
	fieldValidation,
	hex2rgba,
	blackOrWhite,
	cleanInternalLinks,
	atob,
	btoa,
	createSha256Hash,
	splitNewLine,
	wordCount,
	createColors,
	generateEventId,
	rootUrl,
	baseName,
	facebookCountry,
};
