import * as Sentry from '@sentry/browser';
import { fetchCheckout, fetchData } from '../utils/async';
import { addEvent, removeEvent, getCookie, addScript } from '../utils/dom';
import { getPrefix, voyadoLocale, translateObjectData } from '../utils/locale';
import { deepClone } from '../utils/object';
import { fieldValidation, createSha256Hash, generateEventId, facebookCountry } from '../utils/string';
import store from './store';
import { paymentMethodsMap } from '../config';
import getFilterData from '../category/get_filter_data';

let preventPaymentFields = false;

export const getSetup = () => (
	fetchData('setup').then((data) => store.dispatch({ type: 'UPDATE_SETUP', data }))
);

export const getTexts = () => (
	fetchData('texts').then((data) => {
		if (!data) return data;
		window.texts = data.texts;
		// Trigger refresh to update texts async.
		store.dispatch({ type: 'UPDATE_TEXTS' });
		return data;
	})
);

/*
 * CENTRA ECOMMERCE
 */

/*
	const isObject = (obj) => obj && typeof obj === 'object';

	const deepClone = (o, skipKey = false) => {
		const out = Array.isArray(o) ? [] : {};
		if (!isObject(o)) return out;
		Object.keys(o).forEach((key) => {
			if (skipKey && key === skipKey) return;
			const v = o[key];
			out[key] = isObject(v) ? deepClone(v) : v;
		});
		return out;
	};

	let paymentData = {
		address: {
			newsletter: false,
			email: 'abc123@example.com',
			phoneNumber: '123456789',
			firstName: 'Test Billing',
			lastName: 'Testson Billing',
			address1: 'Address One',
			address2: 'Address Two',
			zipCode: '12345',
			city: 'Malmö',
			country: 'SE'
		},
		shippingAddress: {
			email: 'abc123@example.com',
			phoneNumber: '123456789',
			firstName: 'Test Billing',
			lastName: 'Testson Billing',
			address1: 'Address One',
			address2: 'Address Two',
			zipCode: '12345',
			city: 'Malmö',
			country: 'SE'
		},
		consents: [
			{
				key: 'newsletter',
				consented: true,
				name: 'Constent 1',
				text: 'I approved X',
				version: 1,
				language: 'sv_SE'
			}
		],
		affiliate: 'campaign1',
		additionalNotes: 'Message from customer',
		customerClubFields: {
			accountNumber: 'xyz123'
		},
		paymentFailedPage: 'http://www.dedicatedbrand.com/en/selection/failed',
		paymentReturnPage: 'http://www.dedicatedbrand.com/en/selection/receipt',
		termsAndConditions: true,
		email: 'gaynormiller@hotmail.co.uk',
		newsletter: true,
		removeField: true,
	}
	let useShippingAddress = true;
*/

export const removeInvalidPaymentFields = (paymentData, countries, country, useShippingAddress, giftCertificate = false) => {
	const whitelistedFields = {
		level0: ['paymentMethod', 'shippingMethod', 'consents', 'affiliate', 'additionalNotes', 'additionalInformation', 'customerClubFields', 'cartAbandonmentEmail', 'accountNumber', 'additionalFields', 'paymentReturnPage', 'paymentFailedPage', 'termsAndConditions', 'address', 'shippingAddress'],
		address: ['newsletter', 'firstName', 'lastName', 'email', 'phoneNumber', 'company', 'vatNumber', 'address1', 'address2', 'zipCode', 'state', 'city', 'country'],
		shippingAddress: ['firstName', 'lastName', 'email', 'phoneNumber', 'company', 'address1', 'address2', 'zipCode', 'city', 'state', 'country'],
	};

	// Copy only whitelisted fields.
	const returnData = Object.keys(paymentData).reduce((acc, f) => {
		if (whitelistedFields.level0.indexOf(f) === -1) return acc;
		if (whitelistedFields[f]) {
			// Add shippingAddress only if exists
			if (f === 'shippingAddress' && !useShippingAddress) return acc;

			// check second level.
			return {
				...acc,
				[f]: Object.keys(paymentData[f]).reduce((acc1, f1) => {
					if (whitelistedFields[f].indexOf(f1) === -1) return acc1;
					if (f1 === 'phoneNumber' && paymentData?.[f]?.phonePrefix) {
						return { ...acc1, [f1]: `${paymentData[f].phonePrefix}${(paymentData[f][f1] || '').replace(new RegExp(`(\\${(paymentData[f].phonePrefix || '')})+`), '')}` };
					}
					return { ...acc1, [f1]: paymentData[f][f1] };
				}, {}),
			};
		}
		if (typeof paymentData[f] === 'object') return { ...acc, [f]: deepClone(paymentData[f]) };
		return { ...acc, [f]: paymentData[f] };
	}, {});

	// Add required fields
	if (useShippingAddress) {
		returnData.shippingAddress.country = returnData.shippingAddress.country || country;
	}
	returnData.address.country = returnData.address.country || country;

	let additionalNotes = '';
	if (returnData.additionalInformation) {
		additionalNotes = returnData.additionalInformation.map((info) => info?.message).join('\n');
		delete returnData.additionalInformation;
	}
	if (additionalNotes) returnData.additionalNotes = additionalNotes;

	// Check that state is correct and applicable else remove.
	if (!(returnData.address.state && countries?.[returnData.address.country]?.states?.[returnData.address.state])) delete returnData.address.state;
	// Check that state is correct and applicable else remove.
	if (useShippingAddress && !(returnData.shippingAddress.state && countries?.[returnData.shippingAddress.country]?.states?.[returnData.shippingAddress.state])) delete returnData.shippingAddress.state;

	// ///////////// NEWSLETTER FORCED TO TRUE ON PURCHASE //////////////
	returnData.address.newsletter = 1;
	// ///////////// END NEWSLETTER FORCED TO TRUE ON PURCHASE //////////////

	returnData.paymentFailedPage = `${window.location.origin}${getPrefix()}/selection/failed`;
	returnData.paymentReturnPage = `${window.location.origin}${getPrefix()}/${!giftCertificate ? 'selection' : 'gift'}/receipt`;

	return returnData;
};

const getToken = ({ token }) => {
	if (!token) {
		// check if token is in cookie
		const cookieToken = getCookie('token');
		if (cookieToken) Sentry.captureMessage('TOKEN MISSING IN STORE EXISTED IN COOKIE.');
		return cookieToken;
	}
	return token;
};

export const updateSelection = (token, country, language, giftCertificate = false) => (
	fetchCheckout('/selection', token, [], 'GET')
		.then((data) => {
			if (!data || data.errors) return store.dispatch({ type: 'SELECTION_ERROR' });
			if (data.location.country !== country) {
				changeCountry(country);
			}
			if (data.selection.language !== language) {
				return fetchCheckout(`/countries/${country}`, token, { language }, 'PUT')
					.then((newData) => (
						store.dispatch({ type: 'UPDATE_SELECTION', giftCertificate, data: newData })
					))
					.catch((err) => console.log('DEBUG: err', err));
			}
			return store.dispatch({ type: 'UPDATE_SELECTION', giftCertificate, data });
		})
		.catch((err) => console.log('DEBUG: err', err))
);

export const toggleGiftWrap = () => store.dispatch({ type: 'TOGGLE_GIFT_WRAP' });

export const abandonedCart = () => {
	const { storeSettings, payment } = store.getState();
	const { email = '' } = payment.address;
	const { country, language } = storeSettings;
	const post = {
		country,
		language,
	};

	if (fieldValidation(email, 'email')) return Promise.reject(new Error('Email invalid'));

	return fetchData('/newsletter/subscribe', { post: { ...post, email, newsletter: 1 } }, 'POST')
		.then((response) => {
			if (response.success) store.dispatch({ type: 'SUBSCRIBED' });
			return response;
		})
		.catch((err) => console.log('DEBUG: error when subscribing to newsletter ', err));
};

export const subscribeToNewsletter = (fields) => {
	const { storeSettings } = store.getState();
	const token = getToken(store.getState());
	const { country, language } = storeSettings;
	const { email, interest } = fields;
	const post = {
		country,
		language,
	};
	if (interest.length === 1) post.interest = interest[0];

	createSha256Hash(email.toLowerCase().trim()).then((hash) => {
		if (hash) window.localStorage.setItem('hash', hash);
		return hash;
	}).catch(() => {});

	return fetchCheckout(`/newsletter-subscription/${email}`, token, [], 'POST').then(() => (
		fetchData('/newsletter/subscribe', { post: { ...post, email, newsletter: 1 } }, 'POST')
			.then((response) => {
				if (response.success) store.dispatch({ type: 'SUBSCRIBED' });
				return response;
			})
			.catch((err) => console.log('DEBUG: error when subscribing to newsletter ', err))
	));
};

export const addToBag = async (item, quantity = 1) => {
	const { storeSettings } = store.getState();
	const token = getToken(store.getState());
	let data = false;

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	try {
		data = await fetchCheckout(`/items/${item}/quantity/${quantity}`, token, [], 'POST');
	} catch (err) {
		console.log('ERROR: add to bag failed ', err);
		Sentry.captureMessage('ADD TO BAG ERROR: failed to add');
	}

	if (data) {
		if (data.errors) return store.dispatch({ type: 'ADD_TO_BAG_ERROR' });

		if (data.location.country !== storeSettings.country || data.selection.language !== storeSettings.language) {
			// Country or language might be set/changed by user.
			// Update Centra to use the same country/language.
			try {
				const newData = await fetchCheckout(`/countries/${storeSettings.country}`, data.token, { language: storeSettings.language }, 'PUT');
				if (newData) data = deepClone(newData);
			} catch (err) {
				console.log('DEBUG: err', err);
			}
		}

		if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
		const product = item.slice(0, item.indexOf('-'));
		const productData = (window.filter.productIds[product] && window.filter.products[window.filter.productIds[product]]) || {};
		const category = $('#refilt').data('category');
		const categoryDescription = window.filter.filterDescriptions.categories[category] || '';
		const uniqueEventID = generateEventId();
		const selectionItem = data.selection.items.filter((i) => i.item === item)[0] || {};

		if (typeof window.va !== 'undefined') {
			const voyadoCart = {
				cartRef: data.token,
				cartUrl: `${window.location.origin}${getPrefix()}/selection/update/${data.token}`,
				locale: voyadoLocale(storeSettings),
				items: data.selection.items.map((i) => ({ itemId: i.sku, quantity: i.quantity })),
			};

			window.va('cart', voyadoCart);
		}

		window.dataLayer.push({ values: null });
		window.dataLayer.push({
			event: 'AddToCart',
			event_id: uniqueEventID,
			values: {
				value: productData?.price?.priceAsNumber || selectionItem.priceAsNumber,
				currency: storeSettings.currency,
				content_name: productData.name || selectionItem?.product?.name,
				content_type: 'product',
				content_ids: [`6/${facebookCountry(storeSettings)}${productData.uid || `-p${selectionItem?.product?.centraProduct}-v${selectionItem?.product?.centraVariant}-s`}`],
			},
		});

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'AddToCartGA',
			ecommerce: {
				currencyCode: storeSettings.currency,
				add: {
					products: [{
						name: productData.name || selectionItem?.product?.name,
						id: productData.uid ? productData.uid.slice(2, productData.uid.indexOf('-', 2)) : selectionItem?.product?.centraProduct,
						sku: productData?.items?.[item]?.sku ? productData.items[item].sku.replace(/\D+/g, '') : selectionItem.sku.replace(/\D+/g, ''),
						price: productData?.price?.priceAsNumber || selectionItem.priceAsNumber,
						brand: 'DEDICATED',
						category: categoryDescription.split('::').join(' / '),
						variant: (productData.swatch && productData.swatch.desc) || '',
						quantity: 1,
					}],
				},
			},
		});

		window.dataLayer.push({ values: null });
		window.dataLayer.push({
			event: 'AddToCartPin',
			values: {
				value: productData?.price?.priceAsNumber || selectionItem.priceAsNumber,
				order_quantity: 1,
				currency: storeSettings.currency,
			},
		});

		window.dataLayer.push({ values: null });
		window.dataLayer.push({
			event: 'AddToCartTikTok',
			values: {
				content_id: `6/${storeSettings.country.toLowerCase()}${productData.uid || `-p${selectionItem?.product?.centraProduct}-v${selectionItem?.product?.centraVariant}-s`}`,
				content_type: 'product',
				content_name: productData.name || selectionItem?.product?.name || '',
				quantity: 1,
				price: productData?.price?.priceAsNumber || selectionItem.priceAsNumber,
				value: data.selection.totals.grandTotalPriceAsNumber,
				currency: storeSettings.currency,
			},
		});

		// Toggle bag to show/close.
		$('#js-toggleSelection').parent().removeClass('is-empty').addClass('mainNavigation-selection--open');
		setTimeout(() => {
			$('#js-toggleSelection').parent().removeClass('mainNavigation-selection--open');
		}, 2000);

		return store.dispatch({ type: 'UPDATE_SELECTION', data });
	}

	return data;
};

export const addToGift = (giftCertificate) => {
	const { tokenGift } = store.getState();

	return fetchCheckout(`/items/gift-certificates/${giftCertificate}`, tokenGift, [], 'POST')
		.then((data) => {
			if (data.errors) return store.dispatch({ type: 'ADD_TO_GIFT_ERROR' });

			store.dispatch({ type: 'UPDATE_SELECTION', giftCertificate: 1, data });

			return changePaymentMethod(data?.selection?.paymentMethod, 1);
		})
		.catch((err) => console.log('ERROR: When adding gift certificate', err));
};

export const changeQuantity = (line, quantity) => {
	const { storeSettings } = store.getState();
	const token = getToken(store.getState());

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	return fetchCheckout(`/lines/${line}/quantity/${quantity}`, token, [], 'PUT')
		.then((data) => {
			if (typeof window.va !== 'undefined') {
				const voyadoCart = {
					cartRef: data.token,
					cartUrl: `${window.location.origin}${getPrefix()}/selection/update/${data.token}`,
					locale: voyadoLocale(storeSettings),
					items: data.selection.items.map((i) => ({ itemId: i.sku, quantity: i.quantity })),
				};

				window.va('cart', voyadoCart);
			}

			return store.dispatch({ type: 'UPDATE_SELECTION', data });
		})
		.catch((err) => console.log('DEBUG: err', err));
};

export const changeSize = (product, line, quantity, newItem) => {
	const token = getToken(store.getState());

	// Remove previous product
	return fetchCheckout(`/lines/${line}/quantity/0`, token, [], 'PUT')
		.then(() => fetchCheckout(`/items/${newItem}/quantity/${quantity}`, token, [], 'POST'))
		.then((data) => store.dispatch({ type: 'UPDATE_SELECTION', data }))
		.catch((err) => console.log('DEBUG: err', err));
};

export const changeCountry = (country, giftCertificate = false) => {
	const { countries } = store.getState();
	if (!country) country = '0';

	if (!countries[country] || !countries[country].shipTo) return new Promise((resolve, reject) => reject(new Error('Country doesn\'t exist or is un-deliverable')));

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	const { tokenGift } = store.getState();
	const token = getToken(store.getState());
	const storeCountry = countries[country];

	store.dispatch({ type: 'UPDATE_STORE', country: storeCountry });

	return fetchCheckout(`/countries/${country}`, (giftCertificate ? tokenGift : token), [], 'PUT')
		.then((data) => {
			store.dispatch({ type: 'UPDATE_SELECTION', data, giftCertificate });
			getFilterData.init();

			return data;
		})
		.catch((err) => console.log('DEBUG: err', err));
};

export const changeState = (state, country = false) => {
	const token = getToken(store.getState());
	if (!country) country = store.getState().storeSettings.country;

	return fetchCheckout(`/countries/${country}/states/${state}`, token, [], 'PUT')
		.then((data) => store.dispatch({ type: 'UPDATE_SELECTION', data }))
		.catch((err) => console.log('DEBUG: err', err));
};

export const changePaymentMethod = (method, giftCertificate = false) => {
	const token = getToken(store.getState());
	const { tokenGift } = store.getState();

	return fetchCheckout(`/payment-methods/${method}`, (giftCertificate ? tokenGift : token), [], 'PUT')
		.then((data) => {
			// Remove events
			removeEvent(document, 'centra_checkout_payment_callback.adyenDropIn');

			return store.dispatch({ type: 'UPDATE_SELECTION', data, giftCertificate });
		})
		.catch((err) => console.log('DEBUG: err', err));
};

export const changeShippingMethod = (method) => {
	const token = getToken(store.getState());
	return fetchCheckout(`/shipping-methods/${method}`, token, [], 'PUT')
		.then((data) => store.dispatch({ type: 'UPDATE_SELECTION', data }))
		.catch((err) => console.log('DEBUG: err', err));
};

export const addVoucher = (voucherCode) => {
	const token = getToken(store.getState());

	if (fieldValidation(voucherCode, 'text')) {
		store.dispatch({ type: 'VOUCHER_ERROR' });
		return Promise.reject(new Error('Voucher failed to add'));
	}

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	return fetchCheckout('/vouchers', token, { voucher: voucherCode }, 'POST')
		.then((data) => {
			if (data.errors) return store.dispatch({ type: 'VOUCHER_ERROR' });

			store.dispatch({ type: 'VOUCHER_INPUT', value: '' });
			return store.dispatch({ type: 'UPDATE_SELECTION', data });
		})
		.catch((err) => console.log('DEBUG: err', err));
};

export const removeVoucher = (voucherId) => {
	const token = getToken(store.getState());

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	return fetchCheckout(`/vouchers/${voucherId}`, token, {}, 'DELETE')
		.then((data) => store.dispatch({ type: 'UPDATE_SELECTION', data }))
		.catch((err) => console.log('DEBUG: err', err));
};

export const changeAddressInput = (group, id, value) => store.dispatch({ type: 'UPDATE_ADDRESS', group, id, value });

export const booleanChange = (id, value) => store.dispatch({ type: 'BOOLEAN_CHANGE', id, value });

export const changeGiftWrapInput = (value) => {
	const { additionalNotes = '' } = store.getState().payment;
	// Need to clip out first line to ensure it remains.
	store.dispatch({ type: 'UPDATE_ADDRESS', group: '', id: 'additionalNotes', value: `${additionalNotes.match(/^(.*[\s]*)/)[0]}${value}` });
};

export const initiateCaptcha = () => {
	const { selection = {} } = store.getState();
	const $captchaContainer = document.getElementById('js-captchaContainer');

	if (selection?.captcha?.script && $captchaContainer.innerHTML === '') {
		const captchaResolvedPromise = new Promise((resolve, reject) => {
			addEvent(document, 'centra_captcha_result.captchaCheckout', (handler) => {
				console.log('DEBUG: verified', handler.detail?.verified);
				if (handler.detail?.verified) {
					return resolve(true);
				}
				return reject(new Error('Captcha failed'));
			});
		});

		$captchaContainer.innerHTML = selection.captcha.script;
		evaluate($captchaContainer);
		if (window.captchaCallback) window.captchaCallback();

		return captchaResolvedPromise;
	}

	return Promise.resolve();
};

export const initiateKlarna = (giftCertificate = false) => {
	const { tokenGift } = store.getState();
	const token = getToken(store.getState());
	const { selection = {} } = store.getState();
	const hasKlarnaCheckout = paymentMethodsMap.klarnaCheckout.indexOf(selection.paymentMethod) !== -1;

	// If we don't have Klarna or is already loaded reject.
	if (!hasKlarnaCheckout || window.CentraCheckout) return updateKlarna();

	const paymentData = {};
	paymentData.paymentFailedPage = `${window.location.origin}${getPrefix()}/selection/failed`;
	paymentData.paymentReturnPage = `${window.location.origin}${getPrefix()}/${!giftCertificate ? 'selection' : 'gift'}/receipt`;

	return fetchCheckout('/payment', (giftCertificate ? tokenGift : token), paymentData, 'POST')
		.then((data) => {
			if (data.errors && data.errors.stock) {
				store.dispatch({ type: 'UPDATE_SELECTION', data });
				return store.dispatch({
					type: 'INITIATE_SELECTION',
					data: { formHtml: data.selection.pluginFields.paymentHTML },
					error: 'SELECTION_ERROR_STOCK',
				});
			}
			return store.dispatch({ type: 'INITIATE_SELECTION', data });
		})
		.catch((err) => console.log('DEBUG: err', err));
};

export const klarnaLoaded = () => ({ type: 'KLARNA_LOADED' });

export const klarnaUnloaded = () => ({ type: 'KLARNA_UNLOADED' });

export const updateKlarna = (giftCertificate = false) => {
	if (giftCertificate || preventPaymentFields) return false;
	const { tokenGift, payment, countries, useShippingAddress } = store.getState();
	const token = getToken(store.getState());
	const { country } = store.getState().storeSettings;
	const { termsAndConditions, ...paymentData } = removeInvalidPaymentFields(payment, countries, country, useShippingAddress);

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	return fetchCheckout('/payment-fields', (!giftCertificate ? token : tokenGift), paymentData, 'PUT')
		.then((data) => (
			// store.dispatch({ type: 'UPDATE_SELECTION', data, giftCertificate });
			store.dispatch({ type: 'PAYMENT_RETURN', data })
		))
		.catch((err) => console.log('DEBUG: err', err));
};

export const clearKlarna = () => ({ type: 'PAYMENT_RETURN' });

export const initiateIngrid = () => {
	if (window._sw) return Promise.reject(); // eslint-disable-line no-underscore-dangle
	return updateIngrid({ detail: {} })
		.then((data) => {
			ingridLoaded();
			return data;
		})
		.catch((err) => console.log('DEBUG: err', err));
};

const updateIngrid = (event) => {
	const { payment, useShippingAddress, countries } = store.getState();
	const token = getToken(store.getState());
	const { country } = store.getState().storeSettings;
	const { termsAndConditions, ...paymentData } = removeInvalidPaymentFields(payment, countries, country, useShippingAddress);

	if (window.CentraCheckout) window.CentraCheckout.suspend();

	return fetchCheckout('/payment-fields', token, { ...paymentData, ...event.detail }, 'PUT').then((data) => {
		store.dispatch({ type: 'UPDATE_SELECTION', data });

		return store.dispatch({ type: 'PAYMENT_RETURN', data, suspendIgnore: event.detail?.additionalFields?.suspendIgnore });
	}).catch((err) => {
		console.log('DEBUG: err', err);
	});
};

export const ingridLoaded = () => {
	removeEvent(document, 'centra_checkout_callback.ingridCheckout');
	addEvent(document, 'centra_checkout_callback.ingridCheckout', (event) => {
		updateIngrid(event);
	});

	return ({ type: 'INGRID_LOADED' });
};

export const ingridUnloaded = () => {
	removeEvent(document, 'centra_checkout_callback.ingridCheckout');
	return ({ type: 'INGRID_UNLOADED' });
};

export const ingridUpdateMethod = (method) => store.dispatch({ type: 'INGRID_SHIPPING_METHOD', method });

const stripeIntentsUpdate = (event, responseType) => {
	const { shippingCountry, shippingState, shippingMethod } = event.detail;

	const selectionUpdated = (response) => {
		const data = response.data;

		let returnObject = {};
		if (data && data.selection && data.selection.totals) {
			returnObject = {
				country: data.location.country,
				currency: data.selection.currency,
				currencyDenominator: data.selection.currencyFormat.denominator,
				grandTotalPriceAsNumber: data.selection.totals.grandTotalPriceAsNumber,
				shippingMethod: data.selection.shippingMethod,
				shippingMethodsAvailable: data.shippingMethods,
			};
		} else {
			returnObject = {
				error: true,
			};
		}

		const shippingUpdateEvent = new CustomEvent(responseType, { detail: returnObject }); // eslint-disable-line no-undef
		document.dispatchEvent(shippingUpdateEvent);
		return response;
	};

	if (responseType === 'centra_checkout_shipping_method_response') {
		return store.dispatch(changeShippingMethod(shippingMethod))
			.then(selectionUpdated)
			.catch((err) => console.log('DEBUG: err', err));
	}

	if (shippingState) {
		return store.dispatch(changeState(shippingState, shippingCountry))
			.then(selectionUpdated)
			.catch((err) => console.log('DEBUG: err', err));
	}

	return store.dispatch(changeCountry(shippingCountry))
		.then(selectionUpdated)
		.catch((err) => console.log('DEBUG: err', err));
};

const paymentSelected = (event, giftCertificate) => {
	const { selection, languages, countries, tokenGift, storeSettings } = store.getState();
	const token = getToken(store.getState());
	let { payment, useShippingAddress } = store.getState();
	const { country, currency, language } = storeSettings;
	const { paymentMethodSpecificFields, paymentMethod, responseEventRequired, addressIncluded, billingAddress, shippingAddress } = event.detail;
	if (paymentMethod === 'stripe-payment-intents' && addressIncluded) {
		payment = { ...payment, address: { ...billingAddress }, shippingAddress: { ...shippingAddress } };
		useShippingAddress = true;
	}
	const paymentData = removeInvalidPaymentFields(payment, countries, country, useShippingAddress, giftCertificate);
	const postData = {
		paymentMethod,
		paymentMethodSpecificFields,
		...paymentData,
	};

	// Prevent extra clicks while payment is updating.
	store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: true });

	if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'Pay',
		ecommerce: {
			checkout: {
				actionField: { step: 4, option: paymentMethodSpecificFields?.paymentMethod?.brand },
				products: selection.items.map((item) => ({
					name: item.name,
					id: item.id.replace(/p(\d+)-v.*/, '$1'),
					sku: item.sku,
					price: item.priceEachAsNumber,
					brand: item.brand,
					category: item.category,
					variant: item.variant,
					quantity: item.quantity,
				})),
			},
		},
	});

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: 'add_payment_info',
		ecommerce: {
			currency,
			value: selection.totals.grandTotalPriceAsNumber,
			coupon: selection.discounts.vouchers.reduce((acc, obj) => [...acc, obj.voucher], []).join(', '),
			payment_type: paymentMethodSpecificFields?.paymentMethod?.type || paymentMethodSpecificFields?.paymentMethod?.brand || paymentMethod,
			items: selection.items.map((item) => {
				const localizedItem = translateObjectData({ language, languages: { ...languages } }, item);
				const itemCategory = localizedItem.category && localizedItem.category.split('/').reduce((acc, category, j) => ({ ...acc, [`item_category${j > 0 ? j + 1 : ''}`]: category }), {});

				return {
					item_id: localizedItem.sku,
					item_name: localizedItem.name,
					item_brand: localizedItem.brand,
					...(itemCategory || {}),
					item_variant: localizedItem.variant,
					price: localizedItem.priceEachAsNumber,
					quantity: localizedItem.quantity,
				};
			}),
		},
	});

	// Prevent further calls to payment-fields
	preventPaymentFields = true;

	return fetchCheckout('/payment', (!giftCertificate ? token : tokenGift), postData, 'POST')
		.then((response) => {
			store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: false });

			if (response.action === 'redirect') {
				window.location.href = response.url;
				return true;
			}

			if (response.errors) {
				// Payment failed for some reason, show error
				return store.dispatch({ type: 'SELECTION_ERROR', hasError: true });
			}

			if (response.action === 'redirect') {
				window.location.href = response.url;
				return true;
			}

			if (response.action === 'success') {
				window.location.href = `${window.location.origin}${getPrefix()}/${!giftCertificate ? 'selection' : 'gift'}/receipt`;
				return true;
			}

			if (responseEventRequired) {
				// action is javascript
				if (response.formFields) {
					const detail = response.formFields;
					const updateEvent = new CustomEvent('centra_checkout_payment_response', { detail }); // eslint-disable-line no-undef
					document.dispatchEvent(updateEvent);
				} else {
					return store.dispatch({ type: 'SELECTION_ERROR', hasError: true });
				}
			}

			return Promise.reject(new Error('Adyen Drop In listener failed.'));
		}).catch((err) => {
			console.log('DEBUG: err', err);
			store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: false });
		});
};

export const purchase = (giftCertificate = false, paymentInitiateOnly = false, setPaymentMethod = '') => {
	const { payment, useShippingAddress, countries, tokenGift, selection } = store.getState();
	const token = getToken(store.getState());
	const { country } = store.getState().storeSettings;
	const paymentData = removeInvalidPaymentFields(payment, countries, country, useShippingAddress, giftCertificate);
	const hasErrors = [];

	const affiliate = getCookie('affiliateId');
	if (affiliate) paymentData.affiliate = affiliate;

	if (!paymentInitiateOnly) {
		if (!paymentData.address.state && countries?.[paymentData.address.country]?.states) {
			// Missing state
			store.dispatch({ type: 'UPDATE_ERRORS', giftCertificate, group: 'address', id: 'state', error: true });
			hasErrors.push('address_state');
		}

		if (hasErrors.length > 0) {
			if (!giftCertificate) {
				if (typeof window.dataLayer === 'undefined') window.dataLayer = [];
				window.dataLayer.push({
					event: 'PurchaseProceedError',
					ecommerce: {
						checkout: {
							actionField: { step: 2, option: hasErrors.join(', ') },
							products: selection.items.map((item) => ({
								name: item.name,
								id: item.id.replace(/p(\d+)-v.*/, '$1'),
								sku: item.sku,
								price: item.priceEachAsNumber,
								brand: item.brand,
								category: item.category,
								variant: item.variant,
								quantity: item.quantity,
							})),
						},
					},
				});
			}
			return new Promise((resolve, reject) => {
				store.dispatch({ type: 'SELECTION_ERROR', hasError: true });
				reject(new Error('State is incorrect'));
			});
		}
	} else {
		paymentData.paymentInitiateOnly = true;
		paymentData.paymentMethod = setPaymentMethod;
		paymentData.termsAndConditions = true;
	}

	return fetchCheckout('/payment', (!giftCertificate ? token : tokenGift), paymentData, 'POST').then((data) => {
		if (data.action === 'redirect') {
			// Redirect to new URL
			window.location = data.url;
			return false;
		}
		if (data.action === 'form') {
			// Return to checkout to display form

			if (data.formType === 'adyen-drop-in') {
				const $checkoutContainer = document.getElementById('js-adyenDropInContainer');
				$checkoutContainer.innerHTML = data.formHtml;
				$checkoutContainer.style.display = 'block';
				evaluate($checkoutContainer);
				store.dispatch({ type: 'ADYEN_INITIATED' });
				store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: false });

				removeEvent(document, 'centra_checkout_payment_callback.adyenDropIn');
				addEvent(document, 'centra_checkout_payment_callback.adyenDropIn', (event) => {
					if (paymentMethodsMap.adyenDropIn.indexOf(event.detail.paymentMethod) !== -1) paymentSelected(event, giftCertificate);
				});
				return false;
			}

			if (data.formType === 'stripe-checkout') {
				const $checkoutContainer = document.getElementById('js-stripeCheckoutContainer');
				$checkoutContainer.innerHTML = data.formHtml;
				evaluate($checkoutContainer);
				store.dispatch({ type: 'STRIPE_INITIATED' });
				store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: false });
				return false;
			}

			if (data.formType === 'stripe-payment-intents') {
				const $checkoutContainer = document.getElementById('js-stripePaymentIntentsContainer');
				window.stripeRequestButtonSelector = '#stripeCheckoutButton';
				$checkoutContainer.innerHTML = data.formHtml;
				evaluate($checkoutContainer);
				store.dispatch({ type: 'STRIPE_PAYMENT_INTENTS_INITIATED' });
				store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: false });

				removeEvent(document, 'centra_checkout_shipping_address_callback.stripeIntents');
				addEvent(document, 'centra_checkout_shipping_address_callback.stripeIntents', (event) => (
					stripeIntentsUpdate(event, 'centra_checkout_shipping_address_response')
				));
				removeEvent(document, 'centra_checkout_shipping_method_callback.stripeIntents');
				addEvent(document, 'centra_checkout_shipping_method_callback.stripeIntents', (event) => (
					stripeIntentsUpdate(event, 'centra_checkout_shipping_method_response')
				));
				removeEvent(document, 'centra_checkout_payment_callback.stripeIntents');
				addEvent(document, 'centra_checkout_payment_callback.stripeIntents', (event) => {
					if (paymentMethodsMap.stripePaymentIntents.indexOf(event.detail.paymentMethod) !== -1) paymentSelected(event, giftCertificate);
				});
				return false;
			}

			const d = document.createElement('div');
			d.innerHTML = data.formHtml;
			document.body.appendChild(d);
			d.querySelector('form').submit();
			return false;
		}
		if (data.action === 'success') {
			// Purchase complete navigate to receipt.
			window.location.href = `${window.location.origin}${getPrefix()}/${!giftCertificate ? 'selection' : 'gift'}/receipt`;
			return true;
		}
		if (data.errors && data.errors.stock) {
			store.dispatch({ type: 'UPDATE_SELECTION', data, giftCertificate });
			store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: false });
			return store.dispatch({ type: 'SELECTION_ERROR_STOCK', hasError: true });
		}
		return store.dispatch({ type: 'SELECTION_ERROR', hasError: true });
	}).catch((err) => console.log('DEBUG: err', err));
};

export const updatePaymentMethod = (selectionType) => {
	const { adyenInitiated = false, stripeInitiated = false, stripePaymentIntentsInitiated = false } = store.getState();
	if (adyenInitiated || stripeInitiated || stripePaymentIntentsInitiated) {
		store.dispatch({ type: 'PAYMENT_UPDATING', isUpdating: true });
		purchase(selectionType);
	}
};

const evaluate = (subtree, context = window) => {
	function getSnippetType(script) {
		return script.type || '';
	}
	function getRawSnippet(script) {
		return script.text || script.textContent || script.innerHTML || '';
	}

	if (subtree) {
		try {
			const scripts = subtree.getElementsByTagName('script');
			const scriptsAsArray = Array.from(scripts).filter((script) => getSnippetType(script) !== 'application/json');

			scriptsAsArray.forEach((script) => {
				const unescaped = getRawSnippet(script).replace(/\\/gi, '');
				eval.call(context, unescaped); // eslint-disable-line no-eval
			});
		} catch (error) {
			console.error('Caught an error in evaluate function:', error);
		}
	}
};

export const updateVoyadoPurchase = (selection) => {
	// const { payment } = store.getState();
	const { country, language } = store.getState().storeSettings;

	/*
	const interest = [];
	if (payment.male) interest.push('male');
	if (payment.female) interest.push('female');
	*/

	const post = {
		country,
		language,
	};
	// if (interest.length === 1) post.interest = interest[0];
	// if (payment.address.newsletter)
	post.newsletter = 1;

	fetchData('/selection/voyado/purchase', { post, selection }, 'POST')
		.then((response) => {
			if (response.success) store.dispatch({ type: 'SUBSCRIBED' });
			return response;
		})
		.catch((err) => console.log('DEBUG: error when subscribing to newsletter ', err));
};

export const updateVoyadoGiftCard = (selection) => {
	// const { payment } = store.getState();
	const { country, language } = store.getState().storeSettings;

	/*
	const interest = [];
	if (payment.male) interest.push('male');
	if (payment.female) interest.push('female');
	*/

	const post = {
		country,
		language,
	};
	// if (interest.length === 1) post.interest = interest[0];
	// if (payment.address.newsletter)
	post.newsletter = 1;

	fetchData('/selection/voyado/gift-card', { post, selection }, 'POST')
		.then((response) => {
			if (response.success) store.dispatch({ type: 'SUBSCRIBED' });
			return response;
		})
		.catch((err) => console.log('DEBUG: error when subscribing to newsletter ', err));
};

export const paymentResult = (token, giftCertificate = false) => {
	if (token) {
		return (
			fetchCheckout('/payment-result', token, { paymentMethodFields: window.tokens }, 'POST').then((selectionData) => {
				if (selectionData.token && selectionData.order && !selectionData.errors) {
					// fetchData('/gift/receipt/transaction', { selectionData, post }, 'POST');
					if (giftCertificate) updateVoyadoGiftCard(selectionData);
					else updateVoyadoPurchase(selectionData);

					if (typeof window.va !== 'undefined') {
						window.va('emptyCart', { cartRef: selectionData.token });
					}

					return store.dispatch({ type: 'RECEIPT_DATA', data: selectionData, giftCertificate });
				}

				window.location = `${window.location.origin}${getPrefix()}/selection/failed`;
				return store.dispatch({ type: 'SELECTION_FAILED' });
			}).catch(() => store.dispatch({ type: 'SELECTION_FAILED' }))
		);
	}

	return store.dispatch({ type: 'SELECTION_FAILED' });
};

export const destroySelection = (giftCertificate = false) => {
	const { countries } = store.getState();
	const { country } = store.getState().storeSettings;
	const defaultCountrySettings = countries[country];

	// Clear possible campaign market/pricelist to keep in sync with Centra.
	store.dispatch({
		type: 'UPDATE_COUNTRY',
		country,
		market: parseInt(defaultCountrySettings.market, 10),
		pricelist: parseInt(defaultCountrySettings.pricelist, 10),
	});

	// Load in filter data if needed.
	getFilterData.init();

	store.dispatch({ type: 'DESTROY_SELECTION', giftCertificate });
};
