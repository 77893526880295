import * as Sentry from '@sentry/browser';
import { getCookie } from './dom';
import { getPrefix } from './locale';
import { checkoutAPI } from '../config';

const getDataOptions = (body) => {
	const fetchOptions = {
		method: 'GET',
		mode: 'cors',
		cache: 'no-cache',
	};

	if (!body.forceCountry) delete body.forceCountry;
	if (!body.country) delete body.country;
	if (body.forceCountry && body.country) delete body.country;
	if (!body.market) delete body.market;
	if (!body.pricelist) delete body.pricelist;
	if (!body.campaignSite) delete body.campaignSite;

	if (typeof process !== 'undefined' && process.env.CONTENT_API_KEY) {
		fetchOptions.headers = {
			'api-authorization': process.env.CONTENT_API_KEY,
		};
	}

	if (body !== null && typeof body === 'object' && Object.keys(body).length > 0) {
		fetchOptions.method = 'POST';
		fetchOptions.accept = 'application/json, text/plain, */*;';
		fetchOptions.body = JSON.stringify(body);
	}

	return fetchOptions;
};

export const fetchData = (path, body = {}) => {
	const encodedPath = path.split('/').filter((part) => !!part).map((part) => encodeURIComponent(part)).join('/');
	// debug: return fetch(`/${path}`, getDataOptions(body)).then((resp) => console.log('DEBUG: resp', resp)).catch((err) => console.log('DEBUG: was not able to be fetched', err));
	return fetch(`${getPrefix()}/${encodedPath}`, getDataOptions(body)).then((data) => data.json()).catch((err) => console.log(`DEBUG: was not able to be fetched ${getPrefix()}/${encodedPath}`, err));
};

const getCheckoutOptions = (token, body, method) => {
	const fetchOptions = {
		mode: 'cors',
		cache: 'no-cache',
	};

	fetchOptions.method = method;

	if (token) {
		fetchOptions.headers = {
			Accept: 'application/json, text/plain, */*',
			'API-token': token,
		};
	} else {
		if (getCookie('token') || getCookie('tokenGift')) {
			console.trace();
			Sentry.captureMessage('TOKEN EXISTS BUT IS MISSING FROM FETCH.');
		}
		fetchOptions.headers = { Accept: 'application/json, text/plain, */*;' };
	}

	if (typeof body === 'object' && Object.keys(body).length > 0) {
		fetchOptions.body = JSON.stringify(body);
	}

	return fetchOptions;
};

export const fetchCheckout = (path, token, body = {}, method = 'GET') => {
	const { country } = getCookie('store', true);
	const encodedPath = path.split('/').map((part) => encodeURIComponent(part)).join('/');
	return fetch(`${checkoutAPI.replace('/105', country === 'DE' ? '-de/107' : '/105')}${encodedPath}`, getCheckoutOptions(token, body, method))
		.then((data) => data.json())
		.catch((err) => {
			console.log('ERROR: ', err, method, encodedPath, body, !!token);
			Sentry.captureMessage('CENTRA COMMUNICATION ERROR');
		});
};
