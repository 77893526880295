import render from '../shop/render';
import { getCookie } from '../utils/dom';
import Common from '../common';

const getFilterData = {
	init: (callback) => {
		const store = getCookie('store', true) || {};
		const { market = '', pricelist = '', language = '' } = store;
		const searchObject = {
			lastUpdated: Date.now(),
			searchKeys: [],
			searchValues: [],
			filter: {},
		};
		const clearCache = 1644422873331;
		const oldSearchObject = $.extend({}, searchObject);
		let cache = localStorage.getItem(`cache_${market}_${pricelist}_${language}`);

		if (cache !== null) {
			cache = JSON.parse(cache);
			oldSearchObject.lastUpdated = cache.lastUpdated + (10 * 60 * 1000);
			oldSearchObject.searchKeys = (Array.isArray(cache.searchKeys) && cache.searchKeys.slice(0)) || [];
			oldSearchObject.searchValues = (Array.isArray(cache.searchValues) && cache.searchValues.slice(0)) || [];
			oldSearchObject.filter = cache.filter;
		}

		if (cache === null || clearCache > cache.lastUpdated || oldSearchObject.lastUpdated - Date.now() < 0) {
			$.ajax({
				url: `${Common.getOriginAndLocale()}/loadfilter/${market}/${pricelist}`,
				type: 'GET',
				dataType: 'JSON',
				success: (data) => {
					const newSearchObject = $.extend({}, searchObject);
					// Loop through filter types
					Object.keys(data.filterDescriptions).forEach((filterType) => {
						Object.keys(data.filterDescriptions[filterType]).forEach((filter) => {
							newSearchObject.searchKeys.push(`${filterType}::${filter}`);
							if (data.filterDescriptions[filterType][filter].desc !== undefined) {
								newSearchObject.searchValues.push(data.filterDescriptions[filterType][filter].desc);
							} else {
								newSearchObject.searchValues.push(data.filterDescriptions[filterType][filter]);
							}
						});
					});

					data.products.forEach((product) => {
						newSearchObject.searchKeys.push(product.id);
						newSearchObject.searchValues.push(product.name);
					});

					newSearchObject.lastUpdated = Date.now();
					newSearchObject.filter = data;
					window.filter = newSearchObject.filter;
					render();

					// localStorage.setItem(`cache_${market}_${pricelist}_${language}`, JSON.stringify(newSearchObject));
					if (callback !== undefined) callback(newSearchObject);
				},
			});
		}

		if (oldSearchObject.filter) {
			window.filter = oldSearchObject.filter;
			render();
			if (callback !== undefined) callback(oldSearchObject);
		}
	},
};

export default getFilterData;
