import { createStore } from 'redux';
import { fetchData } from '../utils/async';
import { getCookie, setCookie, deleteCookie } from '../utils/dom';
import { deepClone, deepMerge } from '../utils/object';
import { convertToLatin } from '../utils/string';
import render from './render';

const initialState = {
	productCache: {},
	quickShop: {},
	productListItems: {},
	items: {},
	email: {},
	countries: {},
	checkoutLogin: false,
	accountEntry: 'login',
	useShippingAddress: false,
	initiatedScript: false,
	vouchers: {
		currentValue: '',
		error: false,
	},
	formErrors: {},
	payment: {
		address: {},
		shippingAddress: {},
		paymentFailedPage: '',
		paymentReturnPage: '',
		loggedIn: false,
		voyadoId: '',
		createAccount: false,
		termsAndConditions: false,
	},
	klarnaUnloaded: false,
	klarnaLoaded: false,
	ingridUnloaded: false,
	ingridLoaded: false,
	ingridShippingMethod: '',
	adyenInitiated: false,
	stripeInitiated: false,
	stripePaymentIntentsInitiated: false,
	paymentUpdating: false,
	paymentReturn: {},
	addToBagError: false,
	selectionErrors: {},
	account: {},
	storeSettings: {},
	token: '',
	tokenGift: '',
	favourites: [],
	selection: {},
};

const defaultState = localStorage.getItem('state') && window.location.hash !== '#reset-application-state' ? JSON.parse(localStorage.getItem('state')) : deepClone(initialState);

defaultState.storeSettings = getCookie('store', true) || {};

const token = getCookie('token') || '';
defaultState.token = token;

const tokenGift = getCookie('tokenGift') || '';

if (defaultState.token === tokenGift || (defaultState.token === defaultState.tokenGift && tokenGift === defaultState.tokenGift)) {
	defaultState.tokenGift = '';
	deleteCookie('tokenGift');
} else {
	defaultState.tokenGift = tokenGift;
}

if (typeof window.phonePrefixes !== 'undefined') {
	defaultState.payment.address.phonePrefix = window.phonePrefixes[defaultState.storeSettings.country] || '+1';
	defaultState.payment.shippingAddress.phonePrefix = window.phonePrefixes[defaultState.storeSettings.country] || '+1';
}

if (localStorage.getItem('paymentInfo')) {
	defaultState.payment = deepMerge(defaultState.payment, JSON.parse(localStorage.getItem('paymentInfo')));
	defaultState.payment.createAccount = false;
} else {
	defaultState.payment = deepMerge(defaultState.payment, initialState.payment);
}

defaultState.payment.address.country = defaultState.storeSettings.country;
defaultState.payment.shippingAddress.country = defaultState.storeSettings.country;

if (defaultState.payment.address.email && !getCookie('_vaI') && typeof window.va !== 'undefined') {
	// No voyado cookie on ID check if email is registered.
	const response = fetchData('/customer/registered', { post: { email: defaultState.payment.address.email } });
	if (response.id) window.va('setContactId', response.id);
}

if (!defaultState.token) defaultState.selection = {};

const savePaymentInfo = (paymentInfo) => {
	if (typeof localStorage !== 'undefined') {
		// Remove sensitive and duplicate data
		const tempPaymentInfo = deepClone(paymentInfo);

		delete tempPaymentInfo.address.emailOld;
		delete tempPaymentInfo.address.emailNew;
		delete tempPaymentInfo.address.emailRepeat;
		delete tempPaymentInfo.address.emailNewRepeat;
		delete tempPaymentInfo.address.password;
		delete tempPaymentInfo.address.passwordNew;
		delete tempPaymentInfo.address.passwordRepeat;
		delete tempPaymentInfo.address.passwordNewRepeat;
		delete tempPaymentInfo.address.passwordOld;
		tempPaymentInfo.createAccount = false;

		localStorage.setItem('paymentInfo', JSON.stringify(tempPaymentInfo));
	}
};

const updateCheckoutFieldErrors = (state, action, createAccount = false) => {
	if (action.group && state.paymentFields) {
		const field = state.paymentFields?.[action.group]?.[action.id] || { visible: 1, required: 1 };
		if (action.id === 'password' || action.id === 'passwordRepeat') {
			if (createAccount) {
				field.required = true;
				field.visible = true;
			} else {
				field.required = false;
				field.visible = false;
			}
		}
		if (action.id === 'state') field.required = true;

		return {
			...state,
			paymentFields: {
				...state.paymentFields,
				[action.group]: {
					...state.paymentFields[action.group],
					[action.id]: {
						...field,
						error: field.visible && field.required ? action.error : false,
					},
				},
			},
		};
	}
	if (state?.paymentFields?.[action.id]) {
		const singleField = state.paymentFields[action.id];
		return {
			...state,
			paymentFields: {
				...state.paymentFields,
				[action.id]: {
					...state.paymentFields[action.id],
					error: singleField.visible && singleField.required ? action.error : false,
				},
			},
		};
	}
	return {
		...state,
	};
};

const shop = (state = defaultState, action = {}) => {
	// console.log('DEBUG: before', action.type, action, deepClone(state));
	switch (action.type) {
		case 'UPDATE_PRODUCT_CACHE':
			return {
				...state,
				productCache: {
					updated: Date.now(),
					items: deepClone(action.cache.products),
				},
			};

		case 'UPDATE_CATEGORY_CACHE':
			return {
				...state,
				categoryCache: {
					updated: Date.now(),
					items: deepClone(action.cache.categories),
				},
			};

		case 'QUICK_SHOP':
			return {
				...state,
				quickShop: {
					...state.quickShop,
					[action.productID]: deepClone(action.product),
				},
			};

		case 'UPDATE_PRODUCT_LIST_ITEM':
			return {
				...state,
				productListItems: {
					...state.productListItems,
					[action.productID]: deepClone(action.product),
				},
			};

		case 'UPDATE_SETUP':
			return {
				...state,
				countries: {
					...deepClone(action?.data?.countries || {}),
				},
				languages: {
					...deepClone(action?.data?.languages || {}),
				},
			};

		case 'UPDATE_STORE': {
			const storeSettings = {
				...state.storeSettings,
				country: action.country.country || state.storeSettings.country,
			};

			setCookie('store', storeSettings, 14);

			return {
				...state,
				storeSettings,
			};
		}

		case 'SUBSCRIBED':
			return {
				...state,
				email: action.email,
			};

		case 'SELECT_SIZE':
			return {
				...state,
				selectionErrors: {},
				addToBagError: false,
				items: {
					...state.items,
					[action.product]: {
						...state.items[action.product],
						size: action.item,
						quantity: action.quantity,
					},
				},
			};

		case 'ADD_TO_BAG_ERROR':
			return {
				...state,
				addToBagError: true,
			};

		case 'INITIATE_SELECTION':
			return {
				...state,
				paymentReturn: {
					...state.paymentReturn,
					paymentHTML: action.data.formHtml,
				},
			};

		case 'TOGGLE_GIFT_WRAP': {
			const paymentInfo = {
				...state.payment,
				additionalNotes: state.payment.additionalNotes ? '' : 'GIFT WRAP:\n',
			};

			savePaymentInfo(paymentInfo);

			return {
				...state,
				payment: paymentInfo,
			};
		}

		case 'UPDATE_SELECTION': {
			const storeSettings = {
				...state.storeSettings,
				country: action.data.location.country,
				market: action.data.location.market,
				pricelist: action.data.location.pricelist,
				currency: action.data.selection.currency,
			};
			const paymentReturn = {
				...state.paymentReturn,
			};
			if (action?.data?.selection?.centraCheckoutScript) {
				paymentReturn.centraCheckoutScript = action.data.selection.centraCheckoutScript;
			}

			const tokenProperty = action.giftCertificate ? 'tokenGift' : 'token';
			setCookie(tokenProperty, action.data.token, 14);
			setCookie('store', storeSettings, 14);
			// Why does it update if there is no selection ?
			if (!action.data.selection) return { ...state };

			if (window.CentraCheckout) window.CentraCheckout.resume();

			return {
				...state,
				selectionErrors: {},
				addToBagError: false,
				storeSettings,
				[tokenProperty]: action.data.token,
				paymentReturn,
				payment: {
					...state.payment,
					loggedIn: !!action.data.loggedIn,
					voyadoId: (action.data.loggedIn && action.data.loggedIn.voyado_customer_id) || state.payment.voyadoId,
				},
				selection: {
					// Replace all data every time.
					paymentMethod: action.data.selection.paymentMethod,
					shippingMethod: action.data.selection.shippingMethod,
					captcha: deepClone(action.data?.captcha || {}),
					items: action.data.selection.items.map((item) => ({
						anyDiscount: item.anyDiscount,
						brandName: item.product.brandName,
						category: item.product.categoryUri,
						centraProduct: item.product.centraProduct,
						id: `6/${action.data.location.country.toLowerCase()}-p${item.product.centraProduct}-v${item.product.centraVariant}-s`, // Match google merchant feed.
						item: item.item,
						itemSku: item.sku,
						line: item.line,
						name: item.product.name,
						priceEach: item.priceEach,
						priceEachAsNumber: item.priceEachAsNumber,
						priceEachBeforeDiscount: item.priceEachBeforeDiscount,
						priceEachBeforeDiscountAsNumber: item.priceEachBeforeDiscountAsNumber,
						priceEachReduction: item.priceEachReduction,
						priceEachReductionAsNumber: item.priceEachReductionAsNumber,
						priceEachWithoutTax: item.priceEachWithoutTax,
						priceEachWithoutTaxAsNumber: item.priceEachWithoutTaxAsNumber,
						quantity: item.quantity,
						size: item.size,
						sku: item.product.productSku,
						taxPercent: item.taxPercent,
						thumbnail: item?.product?.media?.['ded-small']?.[0] || '',
						totalPrice: item.totalPrice,
						totalPriceAsNumber: item.totalPriceAsNumber,
						uri: item.uri,
						variantName: item.product.variantName,
					})),
					discounts: deepClone(action.data.selection.discounts),
					totals: { ...action.data.selection.totals },
					paymentMethods: action.data.paymentMethods.map((method) => ({
						...method,
					})),
					shippingMethods: action.data.shippingMethods.map((method) => ({
						...method,
					})),
					paymentFields: deepClone(action.data.paymentFields),
				},
			};
		}

		case 'INIT_PAYMENT_INFO': {
			return {
				...state,
				payment: action.paymentInfo,
			};
		}

		case 'KLARNA_UNLOADED':
			return {
				...state,
				selectionErrors: {},
				klarnaUnloaded: true,
			};

		case 'KLARNA_LOADED':
			return {
				...state,
				selectionErrors: {},
				klarnaLoaded: true,
			};

		case 'INGRID_UNLOADED': {
			return {
				...state,
				ingridUnloaded: true,
			};
		}

		case 'INGRID_LOADED': {
			return {
				...state,
				ingridLoaded: true,
			};
		}

		case 'INGRID_SHIPPING_METHOD': {
			return {
				...state,
				ingridShippingMethod: action.method,
			};
		}

		case 'ADYEN_INITIATED': {
			return {
				...state,
				adyenInitiated: true,
			};
		}

		case 'STRIPE_INITIATED': {
			return {
				...state,
				stripeInitiated: true,
			};
		}

		case 'STRIPE_PAYMENT_INTENTS_INITIATED': {
			return {
				...state,
				stripePaymentIntentsInitiated: true,
			};
		}

		case 'PAYMENT_UPDATING': {
			return {
				...state,
				selectionErrors: {},
				paymentUpdating: action.isUpdating,
			};
		}

		case 'UPDATE_ADDRESS': {
			if (!action.id) return state;
			const value = convertToLatin(action.value, true);

			const paymentInfo = action.group ? {
				...state.payment,
				[action.group]: {
					...state.payment[action.group],
					[action.id]: value,
				},
			} : {
				...state.payment,
				[action.id]: value,
			};

			savePaymentInfo(paymentInfo);

			return {
				...state,
				formErrors: {},
				selectionErrors: {},
				payment: paymentInfo,
				selection: {
					...state.selection,
					...updateCheckoutFieldErrors(state.selection, action, paymentInfo.createAccount),
				},
			};
		}

		case 'FORM_ERRORS': {
			return {
				...state,
				formErrors: {
					...state.formErrors,
					...deepClone(action.errors),
				},
			};
		}

		case 'UPDATE_ERRORS': {
			return {
				...state,
				selection: {
					...state.selection,
					...updateCheckoutFieldErrors(state.selection, action, state.payment.createAccount),
				},
			};
		}

		case 'ACCOUNT_FORGOT_SENT':
			return {
				...state,
				selectionSuccess: {
					forgotPasswordSuccess: true,
				},
			};

		case 'ACCOUNT_FORGOT_FAILED':
			delete state.selectionSuccess;

			return {
				...state,
				selectionErrors: {
					...state.selectionErrors,
					forgotPasswordError: action.hasError,
				},
			};

		case 'ACCOUNT_ENTRY_FAILED':
		case 'SELECTION_ERROR':
			return {
				...state,
				selectionErrors: {
					...state.selectionErrors,
					selectionError: action.hasError,
				},
			};

		case 'SELECTION_ERROR_STOCK':
			return {
				...state,
				selectionErrors: {
					...state.selectionErrors,
					selectionErrorStock: action.hasError,
				},
			};

		case 'SELECTION_ERROR_REGISTERED':
			return {
				...state,
				selectionErrors: {
					...state.selectionErrors,
					selectionErrorRegistered: action.hasError,
				},
			};

		case 'SELECTION_ERROR_PASSWORD':
			return {
				...state,
				selectionErrors: {
					...state.selectionErrors,
					selectionErrorPassword: action.hasError,
				},
			};

		case 'BOOLEAN_CHANGE': {
			if (action.id === 'useShipping') {
				return {
					...state,
					selectionErrors: {},
					useShippingAddress: (typeof action.value !== 'undefined' ? action.value : !state.useShippingAddress),
					payment: {
						...state.payment,
						shippingAddress: {
							...state.payment.shippingAddress,
							email: state.payment.shippingAddress.email || '',
							firstName: state.payment.shippingAddress.firstName || '',
							lastName: state.payment.shippingAddress.lastName || '',
							company: state.payment.shippingAddress.company || '',
							phoneNumber: state.payment.shippingAddress.phoneNumber || '',
							address1: state.payment.shippingAddress.address1 || '',
							address2: state.payment.shippingAddress.address2 || '',
							zipCode: state.payment.shippingAddress.zipCode || '',
							city: state.payment.shippingAddress.city || '',
							country: state.payment.shippingAddress.country || '',
						},
					},
				};
			}
			if (action.id === 'subscribeToNewsletter') {
				return {
					...state,
					selectionErrors: {},
					payment: {
						...state.payment,
						address: {
							...state.payment.address,
							newsletter: (typeof action.value !== 'undefined' ? action.value : !state.payment.address.newsletter),
						},
					},
				};
			}

			const paymentInfo = {
				...state.payment,
				[action.id]: (typeof action.value !== 'undefined' ? action.value : !state.payment[action.id]),
			};

			savePaymentInfo(paymentInfo);

			return {
				...state,
				selectionErrors: {},
				payment: paymentInfo,
			};
		}

		case 'VOUCHER_INPUT':
			return {
				...state,
				selectionErrors: {},
				vouchers: {
					...state.vouchers,
					currentValue: action.value,
					error: false,
				},
			};

		case 'VOUCHER_ERROR':
			return {
				...state,
				vouchers: {
					...state.vouchers,
					error: !state.vouchers.error,
				},
			};

		case 'PAYMENT_RETURN':
			if (window.CentraCheckout) window.CentraCheckout.resume();
			if (!action.data && !action.data?.selection?.paymentHTML && !action.data?.selection?.centraCheckoutScript) {
				return {
					...state,
					paymentReturn: {},
				};
			}
			return {
				...state,
				paymentReturn: {
					paymentHTML: action.data?.selection?.paymentHTML,
					centraCheckoutScript: action.data?.selection?.centraCheckoutScript,
				},
			};

		case 'INITIATED_SCRIPT':
			return {
				...state,
				initiatedScript: true,
			};

		case 'RECEIPT_DATA': {
			let receiptSnippet = {};
			if (action.data.order.paymentMethodData && action.data.order.paymentMethodData.snippet) {
				receiptSnippet = action.data.order.paymentMethodData.snippet;
			}

			const tokenProperty = action.giftCertificate ? 'tokenGift' : 'token';

			return {
				...state,
				storeSettings: {
					...state.storeSettings,
				},
				[tokenProperty]: action.data.token,
				payment: {
					...state.payment,
					loggedIn: !!action.data.loggedIn,
					voyadoId: (action.data.loggedIn && action.data.loggedIn.voyado_customer_id) || state.payment.voyadoId,
				},
				selection: {
					// Replace all data every time.
					order: action.data.order.order,
					currency: action.data.order.currency,
					date: action.data.order.date,
					items: action.data.order.items.map((item) => ({
						anyDiscount: item.anyDiscount,
						brandName: item.product.brandName,
						category: item.product.categoryUri,
						centraProduct: item.product.centraProduct,
						id: `6/${action.data.location.country.toLowerCase()}-p${item.product.centraProduct}-v${item.product.centraVariant}-s`, // Match google merchant feed.
						item: item.item,
						itemSku: item.sku,
						line: item.line,
						name: item.product.name,
						priceEach: item.priceEach,
						priceEachAsNumber: item.priceEachAsNumber,
						priceEachBeforeDiscount: item.priceEachBeforeDiscount,
						priceEachBeforeDiscountAsNumber: item.priceEachBeforeDiscountAsNumber,
						priceEachReduction: item.priceEachReduction,
						priceEachReductionAsNumber: item.priceEachReductionAsNumber,
						priceEachWithoutTax: item.priceEachWithoutTax,
						priceEachWithoutTaxAsNumber: item.priceEachWithoutTaxAsNumber,
						quantity: item.quantity,
						size: item.size,
						sku: item.product.productSku,
						taxPercent: item.taxPercent,
						thumbnail: item?.product?.media?.['ded-small']?.[0] || '',
						totalPrice: item.totalPrice,
						totalPriceAsNumber: item.totalPriceAsNumber,
						uri: item.uri,
						variantName: item.product.variantName,
					})),
					discounts: deepClone(action.data.order.discounts),
					totals: { ...action.data.order.totals },
					address: { ...action.data.order.address },
					shippingAddress: { ...action.data.order.shippingAddress },
					paymentMethodName: action.data.order.paymentMethodName,
					shippingMethodName: action.data.order.shippingMethodName,
					affiliateHtml: action.data.order.affiliateHtml,
					affiliateName: action.data.order.affiliateName,
					receiptSnippet,
				},
			};
		}

		case 'RECEIPT_ERROR':
			return {
				...state,
				selectionErrors: {
					...state.selectionErrors,
					selectionError: action.reason,
				},
			};

		case 'UPDATE_COUNTRY': {
			const storeSettings = {
				...state.storeSettings,
				country: action.country,
				market: action.market,
				pricelist: action.pricelist,
			};

			setCookie('store', storeSettings, 14);

			return {
				...state,
				storeSettings,
			};
		}

		case 'DESTROY_SELECTION': {
			deleteCookie('receiptRendered');
			const tokenProperty = action.giftCertificate ? 'tokenGift' : 'token';
			deleteCookie(tokenProperty);

			return {
				...state,
				[tokenProperty]: '',
				items: {},
				useShippingAddress: false,
				vouchers: {
					currentValue: '',
					error: false,
				},
				payment: {
					...state.payment,
					termsAndConditions: false,
					createAccount: false,
					additionalNotes: '',
				},
				paymentReturn: {},
				addToBagError: false,
				selectionErrors: {},
				selection: {},
			};
		}

		case 'TOGGLE_LOGIN': {
			return {
				...state,
				checkoutLogin: !state.checkoutLogin,
			};
		}

		case 'TOGGLE_ACCOUNT_ENTRY': {
			let accountEntry = 'login';
			if (state.accountEntry === 'register' && action.state === 'registerLogin') accountEntry = 'login';
			else if (state.accountEntry === 'login' && action.state === 'registerLogin') accountEntry = 'register';
			else if (state.accountEntry === 'forgot' && action.state === 'loginForgot') accountEntry = 'login';
			else if (state.accountEntry === 'login' && action.state === 'loginForgot') accountEntry = 'forgot';
			else accountEntry = action.state;

			delete state.selectionSuccess;

			return {
				...state,
				selectionErrors: {},
				formErrors: {},
				accountEntry,
			};
		}

		case 'UPDATE_ACCOUNT': {
			const paymentInfo = {
				...state.payment,
				loggedIn: true,
				createAccount: false,
				address: {
					...state.payment.address,
					newsletter: action.data.loggedIn.newsletter || state.payment.address.newsletter,
					email: action.data.loggedIn.email || state.payment.address.email,
					firstName: action.data.loggedIn.firstName || state.payment.address.firstName,
					lastName: action.data.loggedIn.lastName || state.payment.address.lastName,
					company: action.data.loggedIn.company || state.payment.address.company,
					phoneNumber: action.data.loggedIn.phoneNumber || state.payment.address.phoneNumber,
					address1: action.data.loggedIn.address1 || state.payment.address.address1,
					address2: action.data.loggedIn.address2 || state.payment.address.address2,
					zipCode: action.data.loggedIn.zipCode || state.payment.address.zipCode,
					city: action.data.loggedIn.city || state.payment.address.city,
					state: action.data.loggedIn.state || state.payment.address.state,
					country: action.data.loggedIn.country || state.payment.address.country,
					emailOld: '',
					emailNew: '',
					emailRepeat: '',
					emailNewRepeat: '',
					password: '',
					passwordNew: '',
					passwordRepeat: '',
					passwordNewRepeat: '',
					passwordOld: '',
				},
			};

			savePaymentInfo(paymentInfo);

			return {
				...state,
				payment: paymentInfo,
			};
		}

		case 'ACCOUNT_ORDERS': {
			const orders = [
				...(state.account.orders || []),
				...deepClone(action.data.orders),
			].reduce((acc, o) => {
				if (acc.length === 0) return [o];
				// Order doesn't exist in array add.
				if (acc.filter((fo) => fo.order === o.order).length === 0) return [...acc, o];
				// Order exists in array skip.
				return acc;
			}, []);

			return {
				...state,
				account: {
					...state.account,
					orders,
					hasMoreOrders: action.data.ordersPaging.totalSize > orders.length,
				},
			};
		}

		case 'ACCOUNT_LOGOUT': {
			const paymentInfo = {
				...state.payment,
				loggedIn: false,
				voyadoId: '',
			};

			savePaymentInfo(paymentInfo);

			return {
				...state,
				payment: paymentInfo,
				account: {},
			};
		}

		default:
			return state;
	}
};

const store = createStore(shop);

store.subscribe(() => {
	// console.log('DEBUG: after', deepClone(store.getState()));
	render();

	const saveState = deepClone(store.getState(), ['account', 'formErrors', 'payment']);

	// Don't save following state.
	saveState.quickShop = {};
	saveState.account = {};
	saveState.formErrors = {};
	saveState.checkoutLogin = false;
	saveState.paymentUpdating = false;
	saveState.klarnaUnloaded = false;
	saveState.klarnaLoaded = false;
	saveState.ingridUnloaded = false;
	saveState.ingridLoaded = false;
	saveState.adyenInitiated = false;
	saveState.stripeInitiated = false;
	saveState.stripePaymentIntentsInitiated = false;
	saveState.vouchers.currentValue = '';
	saveState.vouchers.error = false;

	localStorage.setItem('state', JSON.stringify(saveState).replace(/</g, '\\u003c'));
});

export default store;

// client clicks button -> we set up an action that listens to that -> we update and save the object -> we then render the changes.
// uni-directional flow.
