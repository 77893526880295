import Common from '../common';

const Map = {
	init() {
		let offset;

		$('#js-storesContainer').on('click', '.js-showOnMap', function (e) {
			e.preventDefault();

			const $this = $(this);
			const parentId = $(this).closest('.storesItem').data('location');

			$('#js-map').reppam('showOnMap', {
				id: parentId,
				callback(success) {
					if (!success) {
						$this.addClass('error');
						$this.after(`<p class="error">${success.msg || ''}</p>`);
					} else {
						offset = $('#js-map').offset().top;
						$('html,body').animate({
							scrollTop: offset - 60,
						}, 300);
					}
				},
			});
		});

		$('#js-map').reppam({
			mapData: `${Common.getOriginAndLocale()}/store_info/ajax`,
			defaultCountry: 'SE',
			urlParams: {
				key: 'AIzaSyBk9Z34Q9IHGECZ_tBtD9Dv2JMWxRr_smc',
			},
			mapOptions: {
				backgroundColor: '#FFFFFF',
				scrollwheel: false,
				styles: [{ stylers: [{ saturation: -100 }, { gamma: 1 }] }, { elementType: 'labels.text.stroke', stylers: [{ visibility: 'off' }] }, { featureType: 'poi.business', elementType: 'labels.text', stylers: [{ visibility: 'off' }] }, { featureType: 'poi.business', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }, { featureType: 'poi.place_of_worship', elementType: 'labels.text', stylers: [{ visibility: 'off' }] }, { featureType: 'poi.place_of_worship', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }, { featureType: 'road', elementType: 'geometry', stylers: [{ visibility: 'simplified' }] },{ featureType: 'water', stylers: [{ visibility: 'on' }, { saturation: 50 }, { gamma: 0 }, { hue: '#50a5d1' }] }, { featureType: 'administrative.neighborhood', elementType: 'labels.text.fill', stylers: [{ color: '#333333' }] }, { featureType: 'road.local', elementType: 'labels.text', stylers: [{ weight: 0.5 }, { color: '#333333' }] }, { featureType: 'transit.station', elementType: 'labels.icon', stylers: [{ gamma: 1 }, { saturation: 50 }] }],
			},
			strokeColor: '#FF8FCC',
			personMarker: {
				url: '/assets/images/mapMarkerSprite.svg',
				size: [12, 12],
				anchor: [6, 6],
				origin: [30, 0],
			},
			singleMarker: {
				url: '/assets/images/mapMarkerSprite.svg',
				size: [25, 36],
				anchor: [12, 35],
			},
			multipleMarker: {
				url: '/assets/images/mapMarkerSprite.svg',
				size: [25, 36],
				anchor: [12, 35],
				textColor: 'transparent',
			},
			useTitleInInfoWindow: true,
		});
	},
};

export default Map;
