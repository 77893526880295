import { generateEventId } from '../utils/string';
import Common from '../common';

const SaveFavorite = {
	init: () => {
		$(document).on('click', '.js-saveToFavorites', function (e) {
			e.preventDefault();
			const id = $(this).data('id');

			SaveFavorite.save(id, false);
		});
	},
	save: (id, force) => {
		const favorites = Common.getCookie('favorites') || '[]';
		const favoritesArray = JSON.parse(decodeURIComponent(favorites));
		const index = favoritesArray.indexOf(id);

		if (index === -1) {
			favoritesArray.unshift(id);
			$(document).find('.js-saveToFavorites').each(function () {
				if ($(this).data('id') === id) $(this).addClass('productList-favorites--selected');
			});
		} else if (!force) {
			favoritesArray.splice(index, 1);
			$(document).find('.productList-favorites--selected').each(function () {
				if ($(this).data('id') === id) $(this).removeClass('productList-favorites--selected');
			});
		}

		if (favoritesArray.length > 0) $('#js-favoritesLink').addClass('mainNavigation-favorites--available');
		else $('#js-favoritesLink').removeClass('mainNavigation-favorites--available');

		Common.setCookie('favorites', favoritesArray);

		if (index === -1) {
			window.dataLayer.push({ values: null });
			window.dataLayer.push({
				event: 'AddToWishlist',
				event_id: generateEventId,
				values: {
					id,
				},
			});

			window.dataLayer.push({ values: null });
			window.dataLayer.push({
				event: 'AddToWishlistTikTok',
				values: {
					content_id: id,
				},
			});
		}
	},
};

export default SaveFavorite;
