import Common from '../common';

const FlexImages = {
	init() {
		const grid = {
			colWidth: 110,
			gutWidth: 25,
			columns: 12,
		};
		const maxPageWidth = 1300;
		const currentWidth = $(window).width();
		const marginWidth = Math.ceil(Math.abs(Common.getGrid(grid, 0)));
		const ratio = currentWidth / maxPageWidth;
		let rowItemTotalWidth = 0;
		let currentContWidth = 0;
		let setNextMargin = true;

		$('.dynamicRow').each(function () {
			rowItemTotalWidth = 0;
			currentContWidth = Math.floor($(this).parent().width());

			if (ratio < 1) {
				$(this).find('.dynamicRow-alignCont').height($(this).data('height') * ratio);
			}

			$(this).find('.dynamicRow-inner').each(function () {
				rowItemTotalWidth += $(this).width() + marginWidth;

				$(this).css('margin-left', '');

				if (setNextMargin) {
					$(this).css('margin-left', 0);
				}

				if (rowItemTotalWidth >= currentContWidth) {
					// set next to margin-left 0;
					setNextMargin = true;
					rowItemTotalWidth = 0;
				} else {
					setNextMargin = false;
				}
			});
		});
	},
};

export default FlexImages;
